::placeholder {
  color: var(--c-c);
}
input[type=button], input[type=text], input[type=email], input[type=submit] {
  -webkit-adiappearance: none;
  border-radius: 0;
}
input[type=text], input[type=email], textarea {
  padding: 15px;
  border: 1px solid var(--c-c);
  border-radius: 4px;
}
input[type=text], input[type=email], textarea {
  width: 100%;
}
select {
  padding: 15px;
  cursor: pointer;
}
// radio、checkboxカスタムの場合
input[type=radio], input[type=checkbox] {
  visibility: hidden;
  position: absolute;
  opacity: 0;
}
label {
  cursor: pointer;
  &:has(input[type=radio]) {
    margin-bottom: 10px;
    padding-left: 24px;
    position: relative;
    display: block;
    &::before {
      @include content;
      width: 16px;
      height: 16px;
      background: var(--c-wht);
      border: 1px solid var(--c-c);
      border-radius: 50%;
      top: calc(50% - 8px);
      left: 0;
    }
    &::after {
      @include content;
      width: 10px;
      height: 10px;
      background: var(--c-blk);
      border-radius: 50%;
      top: calc(50% - 5px);
      left: 3px;
      opacity: 0;
    }
  }
  &:has(input[type=radio]:checked) {
    &::after {
      opacity: 1;
    }
  }
}
.agree {
  label {
    cursor: pointer;
    margin-bottom: 10px;
    padding-left: 24px;
    position: relative;
    display: inline-block;
    &::before {
      @include content;
      width: 16px;
      height: 16px;
      background: var(--c-wht);
      border: 1px solid var(--c-c);
      border-radius: 4px;
      top: calc(50% - 8px);
      left: 0;
    }
    &::after {
      @include content;
      width: 10px;
      height: 5px;
      border-bottom: 2px solid var(--c-wht);
      border-left: 2px solid var(--c-wht);
      transform: rotate(-45deg);
      top: calc(50% - 4px);
      left: 3px;
      opacity: 0;
    }
    &:has(input[type=checkbox]:checked) {
      &::before {
        background: var(--c-blk);
      }
      &::after {
        opacity: 1;
      }
    }
  }
}
// selectboxカスタムの場合
select {
  -webkit-appearance: none;
  appearance: none;
  overflow: hidden;
  display: block;
}
span {
  &:has(.select) {
    display: inline-block;
    border: 1px solid var(--c-c);
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    select {
      padding-right: 20px;
      min-width: 144px;
      position: relative;
    }
    &::after {
      @include content;
      border-top: 8px solid var(--c-blk);
      border-right: 5px solid transparent;
      border-bottom: 0;
      border-left: 5px solid transparent;
      top: calc(50% - 4px);
      right: 15px;
    }
  }
}