.li {
	&-disc {
		> li {
			margin-left: 2em;
			list-style: disc;
			&.notice {
				list-style: none;
				position: relative;
				&::before {
					content: "※";
					position: absolute;
					top: 0;
					left: -1.5em;
				}
			}
		}
	}
	&-num {
		> li {
			margin-left: 1.8em;
			list-style: decimal;
			&.notice {
				list-style: none;
				position: relative;
				&::before {
					content: "※";
					position: absolute;
					top: 0;
					left: -1.3em;
				}
			}
		}
	}
	&-dot {
		> li {
			margin-left: 5px;
			padding-left: 25px;
			position: relative;
			&:not([class="notice"])::before {
				content: "";
				width: 10px;
				height: 10px;
				background: #000;
				border-radius: 5px;
				position: absolute;
				top: 8px;
				left: 5px;
			}
			&.notice {
				list-style: none;
				position: relative;
				&::before {
					content: "※";
					position: absolute;
					top: 0;
					left: 0;
				}
			}
		}
	}
	&-paren {
		> li {
			margin-left: 2em;
			list-style: none;
			position: relative;
			counter-increment: cnt;
			&:not([class="notice"])::before {
				content: "（" counter(cnt) "）";
				margin-left: -3em;
				width: 3em;
				display: inline-block;
				text-align: right;
				position: absolute;
				top: 0;
				left: .3em;
			}
			&.notice {
				list-style: none;
				position: relative;
				&::before {
					content: "※";
					position: absolute;
					top: 0;
					left: -1.5em;
				}
			}
		}
	}
	&-notice {
		> li {
			padding-left: 1.5em;
			position: relative;
			&::before {
				content: "※";
				position: absolute;
				top: 0;
				left: 5px;
			}
		}
	}
}