.page {
    &-index {
        .intro {
            .mv {
                padding: 0 20px;
                width: 100%;
                height: 100vh;
                position: relative;
                .inner {
                    @include flex($h: flex-start);
                }
                @include pc {
                    .inner {
                        padding: 0;
                        max-width: 1320px;
                    }
                }
                @include sp {
                    padding: spw(32) spw(20);
                    height: spw(584);
                }
                .inner {
                    padding: 0;
                    @include sp {
                        align-items: flex-end;
                    }
                }
                h2 {
                    position: relative;
                    z-index: 1;
                    @include flex($v: flex-start);
                    flex-direction: column;
                    gap: 40px;
                    .en {
                        line-height: 1.2;
                        color: var(--c-wht);
                        font-size: 58px;
                        font-family: var(--ff-eb);
                        .row {
                            height: 1.3em;
                            &:nth-child(1) {
                                .is-one {
                                    &:nth-child(4),
                                    &:nth-child(9),
                                    &:nth-child(17) { width: .2em; }
                                }
                            }
                            &:nth-child(2) {
                                .is-one {
                                    &:nth-child(3),
                                    &:nth-child(7),
                                    &:nth-child(18) { width: .2em; }
                                }
                            }
                        }
                    }
                    .jp {
                        @include flex($v: flex-start);
                        flex-direction: column;
                        span {
                            margin-bottom: 10px;
                            padding: 10px;
                            line-height: 1;
                            font-size: 18px;
                            font-family: var(--ff-serif);
                            color: var(--c-blk);
                            background: var(--c-wht);
                            display: inline-block;
                            &:nth-child(2) {
                                padding: 10px 0;
                            }
                        }
                    }
                    @include sp {
                        justify-content: flex-end;
                        align-items: flex-start;
                        gap: spw(16);
                        .en {
                            font-size: spw(33);
                        }
                        .jp {
                            span {
                                font-size: spw(18);
                            }
                        }
                    }
                }
                .sns {
                    position: absolute;
                    right: 40px;
                    bottom: 80px;
                    z-index: 1;
                    @include flex;
                    flex-direction: column;
                    gap: 24px;
                    filter: brightness(100);
                    a {
                        width: 24px;
                        height: 24px;
                    }
                    @include sp {
                        top: 0;
                        right: 0;
                        bottom: initial;
                        gap: spw(10);
                        a {
                            width: spw(24);
                            height: spw(24);
                        }
                    }
                }
                .movie {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    video {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
            .client {
                padding: 32px 0 0;
                border-bottom: 1px solid var(--c-c);
                .logolist {
                    margin-bottom: 30px;
                    @include flex;
                    .slick-slide {
                        margin: 0 10px;
                        height: pcw(112);
                        li {
                            background: var(--c-wht);
                        }
                        * {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }
                .link {
                    margin: 0 auto;
                    padding: 14px 0;
                    max-width: 1160px;
                    font-size: 12px;
                    @include flex;
                    @include ico-arrow;
                    .txt {
                        @include ts;
                    }
                    @include pc {
                        &:hover {
                            .txt {
                                opacity: .6;
                            }
                        }
                    }
                }
                @include sp {
                    padding: 0;
                    .logolist {
                        margin-bottom: 0;
                        padding: spw(18) 0;
                        @include flex;
                        background: var(--c-e);
                        .slick-slide {
                            margin: 0 spw(10);
                            height: spw(80);
                        }
                    }
                    .link {
                        padding: spw(14) spw(20) spw(14) 0;
                        max-width: spw(335);
                        font-size: spw(12);
                        .more {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
        .about {
            padding-bottom: 80px;
            .cols {
                margin: 0 auto;
                padding: 117px 0 135px;
                max-width: 1160px;
                @include flex;
                h2 {
                    font-size: 44px;
                    font-family: var(--ff-serif);
                    @include flex($v: flex-start);
                    flex-direction: column;
                    .row {
                        &:nth-child(2) {
                            transform: translateX(-.4em);
                            .is-one {
                                &:nth-child(6) { letter-spacing: -.4em; }
                            }
                        }
                    }
                    // @include pc {
                    //     & > span {
                    //         &:nth-child(2) {
                    //             transform: translateX(-.6em);
                    //         }
                    //     }
                    // }
                }
                p {
                    width: 460px;
                    span {
                        display: block;
                    }
                }
                // .more {
                //     margin-top: 40px;
                //     margin-left: auto;
                //     width: 280px;
                //     font-size: 16px;
                //     border-bottom: 1px solid var(--c-blk);
                //     display: block;
                //     position: relative;
                //     @include arrow-r($c: blk);
                // }
                @include sp {
                    padding: spw(64) spw(20);
                    max-width: initial;
                    flex-direction: column;
                    h2 {
                        margin-bottom: spw(56);
                        margin-right: spw(-20);
                        font-size: spw(32);
                    }
                    p {
                        margin-bottom: spw(26);
                        width: 100%;
                    }
                    .link {
                        margin: 0 auto;
                        width: spw(255);
                    }
                }
            }
            .movie {
                height: 400px;
                @include flex($v: flex-start);
                position: relative;
                // &::before {
                //     @include content;
                //     width: calc(100% - 80px);
                //     height: 100%;
                //     background: url(https://placehold.jp/f90/ffffff/150x150.png?text=DUMMY) no-repeat 0 0;
                //     background-size: cover;
                //     top: 0;
                //     left: 40px;
                //     filter: blur(20px);
                // }
                .wrap {
                    margin: 0 40px;
                    padding-left: 120px;
                    width: 100%;
                    height: 100%;
                    @include flex($h: flex-start);
                    position: relative;
                    z-index: 1;
                    overflow: hidden;
                    box-shadow: 0 0 17px 0px rgb(137 137 137);
                    &::before, &::after {
                        @include content;
                        z-index: 1;
                        pointer-events: none;
                    }
                    &::before {
                        border-top: 6px solid transparent;
                        border-right: none;
                        border-bottom: 6px solid transparent;
                        border-left: 24px solid var(--c-wht);
                        top: calc(50% - 6px);
                        left: calc(50% - 12px);
                    }
                    &::after {
                        width: 80px;
                        height: 80px;
                        border: 1px solid var(--c-wht);
                        border-radius: 50%;
                        top: calc(50% - 40px);
                        left: calc(50% - 40px);
                    }
                    h3 {
                        font-size: 24px;
                        font-family: var(--ff-eb);
                        font-style: italic;
                        color: var(--c-wht);
                        z-index: 1;
                        pointer-events: none;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
                @include sp {
                    height: spw(446);
                    align-items: center;
                    &::before {
                        display: none;
                    }
                    .wrap {
                        margin: 0;
                        padding-left: 0;
                        align-items: center;
                        &::before {
                            border-top-width: spw(6);
                            border-bottom-width: spw(6);
                            border-left-width: spw(24);
                            top: calc(50% - spw(6));
                            left: calc(50% - spw(12));
                        }
                        &::after {
                            width: spw(80);
                            height: spw(80);
                            top: calc(50% - spw(40));
                            left: calc(50% - spw(40));
                        }
                        h3 {
                            margin: 0 auto;
                            width: spw(150);
                            text-align: center;
                            font-size: spw(14);
                            position: absolute;
                            top: spw(280);
                            left: calc(50% - spw(75));
                        }
                    }
                }
            }
        }
        .lafmethod {
            margin-top: calc((325px - 120px) * -1);
            padding-top: 325px;
            background: var(--c-fb);
            h2 {
                margin-bottom: 80px;
                font-family: var(--ff-serif);
                font-size: 33px;
                text-align: center;
                .is-one {
                    &:nth-child(6),
                    &:nth-child(7),
                    &:nth-child(8) {
                        font-family: var(--ff-eb);
                        font-size: 40px;
                    }
                }
            }
            @include sp {
                margin-top: calc((spw(303) - spw(80)) * -1);
                padding-top: spw(303);
                h2 {
                    margin-bottom: spw(44);
                    font-size: spw(24);
                    em {
                        font-size: inherit;
                    }
                }
            }
            .figure {
                width: 100%;
                height: 410px;
                position: relative;
                .imgs {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .cols {
                    margin: 0 auto;
                    padding: 56px 40px 70px 60px;
                    width: 1000px;
                    @include flex;
                    position: relative;
                    .ttls {
                        width: 306px;
                        h3 {
                            font-size: 48px;
                        }
                    }
                    .txt {
                        width: 520px;
                    }
                    a {
                        font-size: 16px;
                        @include ico-arrow;
                        position: absolute;
                        right: 40px;
                        bottom: 24px;
                    }
                }
                @include sp {
                    height: spw(613);
                    .cols {
                        padding: spw(40) spw(20) spw(48);
                        width: 100%;
                        flex-direction: column;
                        .ttls {
                            width: initial;
                            text-align: center;
                            h3 {
                                font-size: spw(44);
                            }
                        }
                        .txt {
                            width: initial;
                        }
                        a {
                            width: spw(255);
                            font-size: spw(16);
                            position: static;
                        }
                    }
                }
            }
        }
        .service {
            padding-top: 80px;
            background: url(../img/top/bg-service.png);
            position: relative;
            .inner {
                margin-bottom: 118px;
                z-index: 1;
                @include pc {
                    max-width: 1160px;
                }
            }
            .ttls {
                margin-bottom: 32px;
                text-align: center;
                position: relative;
                z-index: 1;
                h2 {
                    margin-bottom: 24px;
                    font-family: var(--ff-eb);
                    font-size: 33px;
                }
                .lead {
                    margin-bottom: 40px;
                    font-size: 14px;
                    text-align: center;
                }
            }
            @include sp {
                margin-top: spw(64);
                padding-top: spw(64);
                &::before {
                    top: spw(160);
                }
                .inner {
                    margin-bottom: spw(104);
                }
                h2 {
                    margin-bottom: spw(64);
                    font-size: spw(44);
                    span {
                        display: block;
                    }
                }
                .ttls {
                    margin-bottom: spw(64);
                    .lead {
                        margin-bottom: 0;
                        font-size: spw(18);
                    }
                }
            }
            ul {
                margin-bottom: 64px;
                border-top: 1px solid var(--c-e);
                position: relative;
                z-index: 1;
                @include flex($h: center);
                gap: 10px;
                li {
                    // width: calc(100% / 3 - 10px);
                    height: 112px;
                    background: var(--c-wht);
                    @include ts;
                    @include pc {
                        border: 1px solid var(--c-e);
                        &:nth-child(-n+3) {
                            width: calc(100% / 3 - 10px);
                        }
                        &:nth-child(n+4) {
                            width: calc(100% / 2 - 10px);
                        }
                    }
                    a {
                        padding: 24px 54px 24px 24px;
                        height: 100%;
                        position: relative;
                        @include flex;
                        &::before {
                            @include content;
                            width: 24px;
                            height: 24px;
                            background: var(--c-blk);
                            border-radius: 50%;
                            top: calc(50% - 12px);
                            right: 20px;
                            @include ts;
                        }
                    }
                    .jp {
                        font-size: 24px;
                        font-family: var(--ff-serif);
                    }
                    .en {
                        font-size: 12px;
                        &::before, &::after {
                            @include content;
                            width: 8px;
                            height: 3px;
                            border-right-width: 1px;
                            border-right-style: solid;
                            border-bottom-width: 1px;
                            border-bottom-style: solid;
                            transform: skewX(45deg);
                            top: calc(50% - 3px);
                            @include ts;
                        }
                        &::before {
                            border-right-color: var(--c-wht);
                            border-bottom-color: var(--c-wht);
                            right: 28px;
                        }
                        &::after {
                            border-right-color: var(--c-blk);
                            border-bottom-color: var(--c-blk);
                            right: 40px;
                            opacity: 0;
                        }
                    }
                    @include pc {
                        &:hover {
                            box-shadow: 4px 4px 8px 0 rgba(17, 17, 17, .08);
                            z-index: 1;
                            a {
                                &::before {
                                    background: var(--c-wht);
                                }
                            }
                            .en {
                                &::before {
                                    right: 0;
                                    opacity: 0;
                                }
                                &::after {
                                    right: 28px;
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
                @include sp {
                    margin-bottom: spw(80);
                    li {
                        width: 100%;
                        height: spw(80);
                        border: 1px solid var(--c-e);
                        border-top: none;
                        a {
                            padding: 0 spw(16);
                        }
                        .jp {
                            font-size: spw(18);
                        }
                        .en {
                            font-size: spw(12);
                            padding-right: spw(34);
                        }
                    }
                }
            }
            .more {
                margin: 0 auto;
            }
        }
        .results {
            margin-bottom: 104px;
            height: 400px;
            color: var(--c-wht);
            background: url(https://placehold.jp/3d4070/ffffff/150x150.png?text=DUMMY) no-repeat 0 0;
            background-size: cover;
            @include flex($h: center);
            gap: 90px;
            position: relative;
            overflow: hidden;
            .bg {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
                top: 0;
                left: 0;
                &::before {
                    @include content;
                    width: 100%;
                    height: 100%;
                    background: var(--c-blk);
                    opacity: 0.4;
                    top: 0;
                    left: 0;
                    z-index: 1;
                }
                video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .items {
                position: relative;
                z-index: 5;
                @include flex($v: flex-start);
                flex-direction: column;
                &::after {
                    @include content;
                    width: 100%;
                    height: 1px;
                    background: var(--c-wht);
                    top: 64px;
                    left: 0;
                    opacity: 0.58;
                    transform-origin: left center;
                    transform: scaleX(0);
                    @include ts;
                }
                .img {
                    height: 70px;
                    clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
                    @include ts($delay: .4s, $duration: .6s);
                }
                p {
                    font-size: 18px;
                    clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
                    @include ts($delay: .2s);
                }
                &.is-show {
                    &::after {
                        transform: scaleX(1);
                    }
                    .img {
                        height: 70px;
                        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
                    }
                    p {
                        font-size: 18px;
                        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
                    }
                }
            }
            @include sp {
                padding: spw(40);
                height: spw(666);
                align-items: flex-start;
                flex-direction: column;
                gap: spw(40);
                .items {
                    flex-direction: column-reverse;
                    gap: spw(10);
                    &::after {
                        top: spw(105);
                    }
                    img {
                        padding-top: 1px;
                        height: spw(70);
                    }
                    p {
                        font-size: spw(18);
                    }
                }
            }
        }
        .information {
            margin-bottom: 80px;
            .inner {
                padding: 0;
                position: relative;
                @include pc {
                    max-width: 1160px;
                }
            }
            .ttls {
                margin-bottom: 64px;
                .en {
                    font-family: var(--ff-eb);
                    font-size: 33px;
                    font-weight: 700;
                }
            }
            ul {
                border-top: 1px solid var(--c-c);
            }
            li {
                border-bottom: 1px solid var(--c-c);
                a {
                    padding: 32px 66px 32px 40px;
                    @include flex($h: flex-start);
                    gap: 20px;
                    position: relative;
                    @include arrow-r($w: 16, $h: 6, $c: --c-org);
                    &::after {
                        right: 38px;
                    }
                }
            }
            .more {
                width: 200px;
                position: absolute;
                top: 0;
                right: 0;
            }
            @include sp {
                margin-bottom: spw(80);
                padding: spw(20);
                .ttls {
                    margin-bottom: spw(48);
                    text-align: center;
                    .en {
                        font-size: spw(33);
                    }
                }
                ul {
                    margin-bottom: spw(48);
                }
                li {
                    a {
                        padding: spw(24) 0;
                        flex-direction: column;
                        align-items: flex-start;
                        &::after {
                            width: spw(16);
                            height: spw(6);
                            border-right-color: var(--c-blk);
                            border-bottom-color: var(--c-blk);
                            // top: calc(50% - spw(4));
                            // right: spw(40);
                            position: absolute;
                            top: spw(30);
                            right: spw(20);
                        }
                    }
                }
                .more {
                    width: spw(255);
                    position: relative;
                    top: initial;
                    left: initial;
                }
            }
        }
        .seminar {
            margin-bottom: 80px;
            position: relative;
            .ttls {
                margin-bottom: 64px;
                width: 200px;
                position: relative;
                left: calc((100vw - 1160px) / 2);
                .en {
                    font-family: var(--ff-eb);
                    font-size: 33px;
                    font-weight: 700;
                }
            }
            .more {
                width: 200px;
                font-size: 16px;
                position: absolute;
                top: 0;
                right: calc((100vw - 1160px) / 2);
            }
            .seminarlist {
                .slick-slide {
                    margin: 0 26px;
                }
                .slick-prev, .slick-next {
                    width: 44px;
                    height: 44px;
                    background: var(--c-wht);
                    border: 1px solid var(--c-c);
                    border-radius: 44px;
                    position: absolute;
                    top: 96px;
                    z-index: 1;
                    cursor: pointer;
                    &::after {
                        @include content;
                        width: 16px;
                        height: 4px;
                        border-bottom: 1px solid var(--c-blk);
                        top: 18px;
                        right: 12px;
                    }
                }
                .slick-prev {
                    left: calc((100vw - 1160px) / 2);
                    &::after {
                        border-left: 1px solid var(--c-blk);
                        transform: skewX(-45deg);
                    }
                }
                .slick-next {
                    right: calc((100vw - 1160px) / 2);
                    &::after {
                        border-right: 1px solid var(--c-blk);
                        transform: skewX(45deg);
                    }
                }
            }
            @include sp {
                margin-bottom: spw(80);
                .ttls {
                    margin: 0 auto spw(48);
                    text-align: center;
                    left: 0;
                    .en {
                        font-size: spw(33);
                    }
                }
                // .slider {
                //     a {
                //         .img {
                //             margin-bottom: spw(24);
                //             height: spw(198);
                //         }
                //         .txt {
                //             margin-bottom: spw(16);
                //         }
                //         .category {
                //             width: calc(100% - spw(80));
                //             li {
                //                 border-radius: 4px;
                //             }
                //         }
                //         .date {
                //             width: spw(80);
                //         }
                //     }
                // }
                .more {
                    width: spw(255);
                    position: relative;
                    top: initial;
                    right: initial;
                }
                .seminarlist {
                        margin-bottom: spw(40);
                        padding-bottom: spw(50);
                    p {
                        margin: 0 0 10px;
                    }
                    .cols {
                        margin: 0;
                    }
                    .category li {
                        border-radius: 4px;
                    }
                    .slick-slide {
                        margin: 0 spw(10);
                    }
                    .slick-prev, .slick-next {
                        width: spw(32);
                        height: spw(32);
                        border-radius: spw(32);
                        top: initial;
                        bottom: 0;
                        &::after {
                            width: spw(16);
                            height: spw(4);
                            top: spw(12);
                            right: spw(8);
                        }
                    }
                    .slick-prev {
                        left: spw(40);
                    }
                    .slick-next {
                        right: spw(40);
                    }
                    .slick-dots {
                        width: calc(100vw - (spw(80) * 2));
                        position: absolute;
                        left: calc(50% - ((100vw - (spw(80) * 2)) / 2));
                        bottom: spw(8);
                    }
                }
            }
        }
        .recruit {
            margin-bottom: 104px;
            padding: 80px 0 64px;
            color: var(--c-wht);
            background: var(--c-wht);
            position: relative;
            overflow: hidden;
            .bg {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .inner {
                @include flex($h: flex-end, $v: flex-start);
                flex-direction: column;
                @include pc {
                    max-width: 1160px;
                    height: 640px;
                }
            }
            .ttls {
                line-height: 1.2;
                position: absolute;
                top: 0;
                right: 0;
                @include flex($h: flex-start, $v: flex-end);
                flex-direction: column;
                .en {
                    font-size: 78px;
                    font-family: var(--ff-eb);
                    font-weight: 700;
                    .is-one {
                        &:nth-child(5) { width: 15px; }
                    }
                }
            }
            h3 {
                font-size: 44px;
                font-family: var(--ff-eb);
                font-weight: 700;
            }
            p {
                width: 520px;
            }
            .more {
                width: 200px;
                color: var(--c-wht);
                position: absolute;
                right: 0;
                bottom: 0;
                &::before {
                    background: var(--c-wht);
                }
                &::after {
                    border-color: var(--c-wht);
                }
            }
            @include sp {
                margin-bottom: spw(80);
                padding: spw(250) spw(20) 0;
                color: var(--c-blk);
                // background-size: 100% auto;
                .bg {
                    height: spw(282);
                }
                .ttls {
                    margin-bottom: spw(20);
                    width: 100%;
                    align-items: center;
                    position: static;
                    .en {
                        font-size: spw(78);
                        mix-blend-mode: difference;
                        filter: brightness(100);
                    }
                }
                h3 {
                    margin-bottom: spw(20);
                    width: 100%;
                    font-size: spw(24);
                    text-align: center;
                }
                p {
                    margin-bottom: spw(40);    
                    width: 100%;
                }
                .more {
                    width: spw(255);
                    color: var(--c-blk);
                    position: relative;
                    &::before {
                        background: var(--c-blk);
                    }
                    &::after {
                        border-color: var(--c-blk);
                    }
                }
            }
        }
        .company {
            margin-bottom: 116px;
            .inner {
                @include pc {
                    max-width: 1160px;
                }
            }
            .ttls {
                margin-bottom: 46px;
                @include flex($v: flex-start);
                flex-direction: column;
                .en {
                    font-size: 33px;
                    font-weight: 700;
                    font-family: var(--ff-eb);
                }
            }
            ul {
                @include flex;
                li {
                    width: 520px;
                    position: relative;
                    @include ts;
                    @include pc {
                        &:hover {
                            opacity: 0.6;
                        }
                    }
                    &::before {
                        @include content;
                        width: 472px;
                        height: 60px;
                        right: 0;
                        bottom: 0;
                        background: var(--c-wht);
                    }
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                    h3 {
                        line-height: 1;
                        font-size: 28px;
                        font-family: var(--ff-eb);
                        position: absolute;
                        right: -14px;
                        bottom: 0;
                    }
                    p {
                        line-height: 1;
                        font-size: 16px;
                        font-family: var(--ff-serif);
                        position: absolute;
                        left: 56px;
                        bottom: 36px;
                    }
                }
            }
            @include sp {
                margin-bottom: spw(80);
                .ttls {
                    margin-bottom: spw(48);
                    align-items: center;
                    .en {
                        font-size: spw(33);
                    }
                }
                ul {
                    flex-direction: column;
                    li {
                        margin-bottom: spw(40);
                        width: 100%;
                        &::before {
                            width: spw(40);
                            height: spw(40);
                            border-right: 1px solid var(--c-c);
                            border-bottom: 1px solid var(--c-c);
                        }
                        &::after {
                            width: spw(16);
                            height: spw(6);
                            border-right: 1px solid var(--c-c);
                            border-bottom: 1px solid var(--c-c);
                            right: spw(12);
                            bottom: spw(12);
                        }
                        a {
                            @include flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 10px;
                        }
                        img {
                            height: spw(168);
                            order: 1;
                        }
                        h3 {
                            order: 3;
                            font-size: spw(24);
                            position: static;
                            transform: translate(-.6em);
                        }
                        p {
                            order: 2;
                            line-height: 1;
                            font-size: 16px;
                            position: static;
                        }
                    }
                }
            }
        }
        .officialsns {
            margin-bottom: 116px;
            height: 480px;
            color: var(--c-wht);
            background: #2f2f2f;
            position: relative;
            .bg {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
                top: 0;
                left: 0;
                &::before {
                    @include content;
                    width: 100%;
                    height: 100%;
                    background: var(--c-blk);
                    opacity: 0.4;
                    top: 0;
                    left: 0;
                    z-index: 1;
                }
                video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .inner {
                z-index: 5;
                @include flex;
                @include pc {
                    max-width: 1160px;
                }
            }
            h2 {
                @include flex($v: flex-start);
                flex-direction: column;
                .en {
                    font-size: 33px;
                    font-weight: 700;
                    font-family: var(--ff-eb);
                    .is-one {
                        &:nth-child(9) { width: 15px; }
                    }
                }
            }
            ul {
                width: 148px;
                @include flex;
                a {
                    font-family: var(--ff-eb);
                    font-size: 14px;
                    @include flex;
                    flex-direction: column;
                    .logo {
                        width: 64px;
                        height: 64px;
                        border: 1px solid var(--c-wht);
                        border-radius: 64px;
                        @include flex($h: center);
                        img {
                            filter: brightness(100);
                        }
                    }
                }
            }
            @include sp {
                margin-bottom: spw(80);
                height: spw(395);
                .inner {
                    flex-direction: column;
                    justify-content: center;
                    gap: spw(46);
                }
                h2 {
                    align-items: center;
                    .en {
                        font-size: 33px;
                        font-weight: 700;
                        font-family: var(--ff-eb);
                    }
                }
                ul {
                    width: spw(148);
                    a {
                        font-size: spw(14);
                        .logo {
                            width: spw(64);
                            height: spw(64);
                            border-radius: spw(64);
                        }
                    }
                }
            }
        }
    }
    &-seminar {
        .head {
            color: var(--c-blk);
            border-bottom: 1px solid var(--c-c);
        }
        .pagettl {
            padding: 55px 0 48px;
            font-size: 24px;
            font-family: var(--ff-serif);
            font-weight: 700;
            text-align: center;
        }
        .seminar {
            .inner {
                padding: 0;
                position: relative;
                @include pc {
                    max-width: 1160px;
                }
            }
        }
        @include sp {
            .pagettl {
                padding: spw(48) 0;
                font-size: spw(24);
            }
        }
        .intro {
            background: var(--c-f8);
            .inner {
                margin-bottom: -90px;
                padding: 80px 0 160px;
                max-width: 920px;
                min-width: initial;
                @include flex($v: flex-start);
            }
            .txts {
                max-width: 460px;
                .category {
                    margin-bottom: 5px;
                    padding-left: 22px;
                    font-size: 14px;
                    font-weight: 400;
                    position: relative;
                    &::before {
                        @include content;
                        width: 12px;
                        height: 12px;
                        background: var(--c-org);
                        border-radius: 50%;
                        top: calc(50% - 6px);
                        left: 0;
                    }
                }
                h2 {
                    margin-bottom: 48px;
                    font-size: 24px;
                }
                .tag {
                    font-size: 18px;
                    @include flex($h: flex-start);
                    gap: 20px;
                }
            }
            .imgs {
                max-width: 400px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            @include sp {
                .inner {
                    margin-bottom: spw(-105);
                    padding: spw(20) spw(20) spw(145);
                    max-width: initial;
                    flex-direction: column-reverse;
                    gap: spw(20);
                }
                .txts {
                    width: 100%;
                    .category {
                        margin-bottom: spw(5);
                        padding-left: spw(22);
                        font-size: spw(14);
                        &::before {
                            width: spw(12);
                            height: spw(12);
                            top: calc(50% - spw(6));
                        }
                    }
                    h2 {
                        margin-bottom: spw(48);
                        font-size: spw(24);
                    }
                    .tag {
                        font-size: spw(16);
                        gap: spw(10);
                    }
                }
                .imgs {
                    width: 100%;
                }
            }
        }
        .information {
            margin: 0 auto 120px;
            max-width: 920px;
            border: 1px solid var(--c-c);
            position: relative;
            z-index: 1;
            h3 {
                padding: 16px 40px;
                font-size: 18px;
                font-weight: 700;
                background: var(--c-f8);
                border-bottom: 1px solid var(--c-c);
            }
            dl {
                padding: 20px 40px;
                font-size: 18px;
                background: var(--c-wht);
                @include flex($v: flex-start);
                dt {
                    padding-right: 10px;
                    width: 130px;
                    position: relative;
                    &::after {
                        content: "：";
                        display: block;
                        position: absolute;
                        top: 0;
                        right: 0;
                    }
                }
                dd {
                    width: calc(100% - 150px);
                }
            }
            a {
                margin: 20px 40px;
                padding: 8px 16px;
                color: var(--c-wht);
                background: var(--c-blk);
                border-radius: 4px;
                display: inline-block;
            }
            @include sp {
                margin-bottom: spw(80);
                width: spw(335);
                h3 {
                    padding: spw(12) spw(20);
                    font-size: spw(18);
                }
                dl {
                    padding: spw(20) spw(20);
                    font-size: spw(14);
                    dt {
                        width: spw(100);
                    }
                    dd {
                        width: calc(100% - spw(100));
                    }
                }
                a {
                    margin: spw(20) spw(20);
                }
            }
        }
    }
    &-about {
        .head {
            height: pcw(700);
            background: url(../img/about/bg-ttl-pc.png) no-repeat center center;
            background-size: cover;
            @include sp {
                height: spw(300);
                background-image: url(../img/about/bg-ttl-sp.png);
            }
        }
        .intro {
            margin: 0 auto;
            padding: 104px 0 120px;
            max-width: 920px;
            position: relative;
            @include flex($v: flex-end);
            &::after {
                @include content;
                height: 1px;
                width: pcw(1320);
                background: var(--c-c);
                left: calc(50% - pcw(660));
                bottom: 0;
            }
            .txts {
                padding-left: 80px;
                width: 560px;
                h3 {
                    margin-bottom: 48px;
                    font-size: 33px;
                    font-family: var(--ff-serif);
                }
                .catch {
                    font-size: 136px;
                    font-family: var(--ff-eb);
                    color: var(--c-blk);
                    position: absolute;
                    top: 66px;
                    left: 207px;
                    mix-blend-mode: difference;
                    filter: brightness(100);
                }
                p {
                    margin-left: 15px;
                    line-height: 2;
                }
            }
            .imgs {
                margin-bottom: 167px;
                width: 400px;
                height: 500px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            h3 {
                max-width: 396px;
                font-size: 33px;
                font-weight: 700;
            }
            .txts {
                max-width: 560px;
                font-size: 18px;
            }
            @include sp {
                padding: spw(64) 0 0;
                width: 100%;
                flex-direction: column-reverse;
                align-items: center;
                gap: spw(40) 0;
                .txts {
                    margin: 0 auto spw(40);
                    padding: 0;
                    width: spw(335);
                    h3 {
                        margin-bottom: spw(38);
                        font-size: spw(33);
                        span {
                            &:nth-of-type(1) {
                                margin-left: -.5em;
                            }
                            &:nth-of-type(2) {
                                margin-right: -.5em;
                            }
                        }
                    }
                    .catch {
                        line-height: 1.2;
                        font-size: spw(78);
                        top: spw(80);
                        left: spw(10);
                    }
                    p {
                        margin-left: 0;
                        font-size: spw(14);
                    }
                }
                .imgs {
                    margin: 0 0 0 auto;
                    width: spw(255);
                    height: spw(320);
                }
                h3 {
                    max-width: initial;
                    font-size: spw(33);
                }
                // .txts {
                //     max-width: initial;
                //     font-size: spw(18);
                // }
            }
        }
        .loadmap {
            padding: 24px 0 0;
            position: relative;
            z-index: 1;
            &::after {
                @include content;
                height: 1px;
                width: pcw(1320);
                background: var(--c-c);
                left: calc(50% - pcw(660));
                bottom: -120px;
            }
            .ttls {
                margin-bottom: 40px;
                text-align: center;
            }
            .imgs {
                img {
                    width: 100%;
                }
            }
            @include sp {
                padding: spw(80) 0 0;
                &::after {
                    bottom: spw(-60);
                }
                .ttls {
                    margin-bottom: spw(40);
                }
                .imgs {
                    overflow-x: scroll;
                    .img {
                        width: spw(1202);
                        &::after {
                            height: spw(315);
                        }
                    }
                }
            }
        }
        // .message {
        //     padding: 104px 0;
        //     color: var(--c-wht);
        //     position: relative;
        //     &::after {
        //         @include content;
        //         width: pcw(1000);
        //         height: 100%;
        //         background: var(--c-3);
        //         top: 0;
        //         left: 0;
        //     }
        //     .txts {
        //         margin-left: calc((100% - pcw(1160)) / 2);
        //         width: pcw(480);
        //         position: relative;
        //         z-index: 5;
        //         h3 {
        //             margin-bottom: 48px;
        //             .en {
        //                 font-size: 44px;
        //                 font-weight: 700;
        //             }
        //         }
        //     }
        //     .imgs {
        //         width: pcw(700);
        //         height: pcw(536);
        //         position: absolute;
        //         top: 80px;
        //         right: 0;
        //         z-index: 1;
        //     }
        // }
        .mission {
            padding: 144px 0 120px;
            background: var(--c-f8);
            position: relative;
            &::after {
                @include content;
                height: 1px;
                width: pcw(1320);
                background: var(--c-c);
                left: calc(50% - pcw(660));
                bottom: 0;
            }
            .inner {
                @include pc {
                    max-width: 1160px;
                }
            }
            .ttls {
                margin-bottom: 63px;
                text-align: center;
            }
            .cols {
                @include flex($v: flex-start);
                dt {
                    margin-left: 80px;
                    width: 393px;
                    font-size: 24px;
                    font-family: var(--ff-serif);
                    font-weight: 700;
                }
                dd {
                    width: 433px;
                }
            }
            @include sp {
                padding-top: spw(80);
                padding-bottom: spw(40);
                .ttls {
                    margin-bottom: spw(40);
                }
                .cols {
                    flex-direction: column;
                    dt {
                        margin-left: 0;
                        width: 100%;
                        font-size: spw(24);
                    }
                    dd {
                        width: 100%;
                    }
                }
            }
        }
        .philosophy {
            margin-right: auto;
            margin-left: auto;
            padding: 24px 0 120px;
            background: var(--c-f8);
            position: relative;
            &::after {
                @include content;
                height: 1px;
                width: pcw(1320);
                background: var(--c-c);
                left: calc(50% - pcw(660));
                bottom: 0;
            }
            .ttls {
                margin-bottom: 64px;
                text-align: center;
            }
            .lead {
                font-size: 33px;
                font-family: var(--ff-serif);
                font-weight: 500;
                text-align: center;
            }
            @include sp {
                padding: spw(40) 0;
                .ttls {
                    margin-bottom: spw(64);
                }
                .lead {
                    font-size: spw(33);
                }
            }
        }
        .credo {
            margin-right: auto;
            margin-left: auto;
            padding: 24px 0 120px;
            background: var(--c-f8);
            .ttls {
                margin-bottom: 64px;
                text-align: center;
            }
            .lists {
                margin: 0 auto;
                max-width: 1000px;
            }
            .card {
                padding: 40px;
                // width: 48%;
                background: var(--c-wht);
                // border: 1px solid var(--c-c);
                @include flex;
                position: relative;
                counter-increment: num;
                &:not(:last-child) {
                    margin-bottom: 20px;
                }
                h5 {
                    padding-left: 90px;
                    &::after {
                        content: counter(num, decimal-leading-zero);
                        font-size: 58px;
                        font-family: var(--ff-eb);
                        font-weight: 400;
                        color: var(--c-org);
                        position: absolute;
                        top: calc(50% - 49px);
                        left: 40px;
                        transform: skewX(-10deg);
                    }
                    .en {
                      line-height: 1.4;
                      font-size: 33px;
                      font-family: var(--ff-eb);
                      font-weight: 500;
                      display: block;
                    }
                    .jp {
                      line-height: 1.4;
                      font-size: 20px;
                      font-family: var(--ff-serif);
                      font-weight: 500;
                      display: block;
                    }
                }
                p {
                    width: 420px;
                    line-height: 2;
                    font-family: var(--ff-serif);
                    font-weight: 700;
                }
            }
            @include sp {
                padding: spw(40) 0;
                .ttls {
                    margin-bottom: spw(64);
                }
                .lists {
                    width: 100%;
                    gap: spw(8) 0;
                }
                .card {
                    padding: spw(12) spw(20) spw(20);
                    width: 100%;
                    h5 {
                        margin-bottom: spw(20);
                        padding-left: spw(60);
                        &::after {
                            font-size: spw(58);
                            top: spw(8);
                            left: spw(20);
                        }
                        .en {
                          font-size: spw(33);
                        }
                        .jp {
                          font-size: spw(20);
                        }
                    }
                }
            }
        }
    }
    &-service {
        &.fixbg {
            &::before {
                background-image: url(../img/service/bg-ttl-pc.png);
                @include sp {
                    background-image: url(../img/service/bg-ttl-sp.png);
                }
            }
        }
        @include sp {
            .pagettl {
                margin-bottom: spw(20);    
                padding: spw(20);
                font-size: spw(16);
            }
        }
        .schedule {
            padding-top: 103px;
            .ttls {
                position: relative;
                z-index: 1;
            }
            h4 {
                margin: 0 auto 40px;
                max-width: 1160px;
            }
            .lead {
                font-size: 33px;
                font-family: var(--ff-serif);
                font-weight: 500;
                text-align: center;
                position: relative;
                z-index: 1;
            }
            .imgs {
                text-align: center;
                position: relative;
                .img {
                    padding: 0 10px 40px;
                    position: relative;
                    img {
                        margin: 0 auto;
                        width: 1206px;
                        position: relative;
                        z-index: 1;
                    }
                    &::after {
                        @include content;
                        width: 100%;
                        height: 236px;
                        background: var(--c-f8);
                        left: 0;
                        bottom: 0;
                    }
                }
            }
            @include sp {
                padding-top: spw(20);
                h4 {
                    margin-bottom: spw(40);
                    padding: spw(20);
                }
                .lead {
                    font-size: spw(24);
                }
                .imgs {
                    overflow-x: scroll;
                    .img {
                        padding: 0 spw(10) spw(40);
                        width: spw(1202);
                        &::after {
                            height: spw(215);
                        }
                    }
                }
            }
        }
        .detail {
            padding-top: 148px;
            @include sp {
                padding-top: spw(64);
            }
            .anchor {
                margin: 0 auto 104px;
                max-width: 1160px;
                display: flex;
                justify-content: center;
                gap: 10px;
                li {
                    width: calc(100% / 5);
                    text-align: center;
                    background: var(--c-wht);
                    border: 1px solid var(--c-e);
                    &:last-child {
                        border-right: 1px solid var(--c-e);
                    }
                    a {
                        padding: 34px 0 42px;
                        width: 100%;
                        font-size: 24px;
                        font-family: var(--ff-serif);
                        display: inline-block;
                        position: relative;
                        @include ts;
                        &::before {
                            @include content;
                            width: 16px;
                            height: 24px;
                            background: url(../img/service/arrow.svg) no-repeat 0 0;
                            background-size: contain;
                            left: calc(50% - 8px);
                            bottom: 10px;
                            opacity: 0.4;
                            @include ts;
                        }
                        &::after {
                            @include content;
                            width: 24px;
                            height: 2px;
                            background: var(--c-org);
                            left: calc(50% - 12px);
                            bottom: 0;
                        }
                        @include pc {
                            &:hover {
                                box-shadow: 8px 8px 8px 0 rgba(204, 204, 204, .4);
                                z-index: 1;
                                &::before {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
                @include sp {
                    margin-bottom: spw(164);
                    padding: 0 spw(20);
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    li {
                        width: calc(100% / 2 - spw(5));
                        border: 1px solid var(--c-e);
                        a {
                            padding: spw(34) 0 spw(42);
                            font-size: spw(14);
                            &::before {
                                width: spw(16);
                                height: spw(24);
                                left: calc(50% - spw(8));
                                bottom: spw(10);
                            }
                            &::after {
                                width: spw(24);
                                height: spw(2);
                                left: calc(50% - spw(12));
                            }
                        }
                    }
                }
            }
            .block {
                margin-top: -40px;
                padding: 80px 0 104px;
                border-top: 1px solid var(--c-org);
                position: relative;
                &.single {
                    .inner {
                        max-width: 480px;
                    }
                    .card {
                        width: 100%;
                    }
                }
                &::before {
                    @include content;
                    border-top: 16px solid transparent;
                    border-right: 0;
                    border-bottom: 16px solid transparent;
                    border-left: 28px solid var(--c-org);
                    top: -16px;
                    left: calc(50% - 14px);
                }
                .inner {
                    margin: 0 auto;
                    padding: 0;
                    max-width: 1000px;
                    min-width: initial;
                }
                h4 {
                    margin-bottom: 30px;
                    text-align: center;
                    span {
                        padding: 0 46px;
                        font-family: var(--ff-serif);
                        font-size: 33px;
                        font-weight: 500;
                        position: relative;
                        &::before, &::after {
                            @include content;
                            width: 30px;
                            height: 30px;
                            background: url(../img/service/ttlico.svg) no-repeat 0 0;
                            background-size: contain;
                            top: calc(50% - 15px);
                        }
                        &::before {
                            left: 0;
                        }
                        &::after {
                            right: 0;
                            transform: rotate(180deg);
                        }
                    }
                }
                .lead {
                    margin-bottom: 32px;
                    font-size: 16px;
                    text-align: center;
                }
                p {
                    margin: 0 auto 32px;
                    max-width: 600px;
                }
                h5 {
                    margin-bottom: 20px;
                    font-size: 16px;
                    font-weight: 500;
                }
                .cols {
                    margin-bottom: 104px;
                    @include flex($h: center, $v: flex-start);
                    gap: 40px;
                }
                .card {
                    padding: 33px 25px;
                    width: calc(100% / 2 - 20px);
                    background: var(--c-f8);
                    align-self: stretch;
                    dt {
                        margin-bottom: 14px;
                        font-size: 24px;
                        font-family: var(--ff-serif);
                        font-weight: 500;
                    }
                    dd {
                        font-size: 18px;
                    }
                }
                @include sp {
                    margin-top: spw(-40);
                    padding: spw(80) 0 spw(104);
                    &::before {
                        border-top-width: spw(16);
                        border-bottom-width: spw(16);
                        border-left-width: spw(28);
                        top: spw(-16);
                        left: calc(50% - spw(14));
                    }
                    .inner {
                        padding: 0 spw(20);
                    }
                    h4 {
                        margin-bottom: spw(30);
                        span {
                            padding: 0 spw(30);
                            font-size: spw(28);
                            &::before, &::after {
                                width: spw(20);
                                height: spw(20);
                                top: calc(50% - spw(10));
                            }
                        }
                    }
                    .lead {
                        margin-bottom: spw(32);
                        font-size: spw(16);
                    }
                    p {
                        margin-bottom: spw(32);
                        max-width: spw(600);
                    }
                    h5 {
                        margin-bottom: spw(20);
                        font-size: spw(16);
                        text-align: center;
                    }
                    .cols {
                        margin-bottom: spw(64);
                        flex-direction: column;
                        gap: spw(12);
                    }
                    .card {
                        padding: spw(33) spw(25);
                        width: 100%;
                        align-self: flex-start;
                        dt {
                            margin-bottom: spw(14);
                            font-size: spw(24);
                        }
                        dd {
                            font-size: spw(14);
                        }
                    }
                }
            }
            .btn {
                width: 280px;
            }
        }
    }
    &-staff {
        &.fixbg {
            &::before {
                background-image: url(../img/staff/bg-ttl-pc.png);
                @include sp {
                    background-image: url(../img/staff/bg-ttl-sp.png);
                }
            }
        }
        .breadcrumb {
            height: 115px;
            position: absolute;
        }
        .joinus {
            .inner {
                background: var(--c-f8);
                @include sp {
                    padding: 0;
                    width: 100%;
                }
            }
            @include pc {
                padding: 0 20px;
                position: relative;
                &::before {
                    @include content;
                    background: var(--c-e2);
                    width: 100%;
                    height: 100%;
                    top: 120px;
                    left: 0;
                }
                .inner {
                    max-width: 1320px;
                    padding: 0;
                    @include flex;
                    flex-direction: row-reverse;
                    z-index: 1;
                }
                .imgs {
                    width: 50%;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .txts {
                    padding: 113px 120px;
                    width: 50%;
                    .ttls {
                        margin-bottom: 32px;
                    }
                    .txt {
                        margin-bottom: 48px;
                    }
                }
            }
            @include sp {
                .imgs {
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .txts {
                    padding: spw(30) spw(20) spw(64);
                    .ttls {
                        margin-bottom: spw(40);
                        text-align: center;
                    }
                    .txt {
                        margin-bottom: 40px;
                    }
                    .more {
                        margin: 0 auto;
                    }
                }
            }
        }
        .intro {
            margin-bottom: 105px;
            position: relative;
            &::before {
                @include content;
                width: pcw(1320);
                width: calc(100vw - ((100vw - 1000px) / 2 / 2));
                height: pcw(1600);
                background: var(--c-f8);
                top: 115px;
                left: 0;
                z-index: 0;
            }
            @include sp {
                margin-bottom: 0;
                &::before {
                    display: none;
                }
            }
            .wrap {
                display: grid;
                grid-template-columns: 1fr pcw(1000);
                grid-template-rows: 195px 1fr 1fr;
                gap: 0 80px;
                position: relative;
                z-index: 1;
                @include sp {
                    margin: 0;
                    padding-bottom: spw(64);
                    background: var(--c-f8);
                    display: block;
                }
            }
            .ttls {
                margin-bottom: 158px;
                padding-left: 80px;
                line-height: 1;
                grid-column: 1;
                grid-row: 2;
                .position {
                    margin-bottom: 24px;
                    font-weight: 500;
                    display: block;
                    @include ico-org;
                }
                .en {
                    margin-bottom: 16px;
                }
                @include sp {
                    margin-bottom: spw(10);
                    padding: spw(24) spw(20) spw(16);
                    @include flex($v: flex-end);
                    .position {
                        margin-bottom: spw(15);
                        padding-left: spw(22);
                        width: 100%;
                    }
                    .en {
                        margin-bottom: 0;
                        width: 60%;
                    }
                    .jp {
                        width: 40%;
                        font-family: var(--ff-serif);
                        text-align: right;
                        transform: translateY(spw(-10));
                    }
                }
            }
            .imgs {
                width: pcw(900);
                height: pcw(750);
                grid-column: 2;
                grid-row: 1 / span 3;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                @include sp {
                    margin-bottom: spw(32);
                }
            }
            .txts {
                padding-left: 80px;
                grid-column: 1;
                grid-row: 3;
                @include sp {
                    padding: 0 spw(20);
                }
            }
        }
        .profile {
            margin-bottom: 104px;
            .inner {
                padding: 40px;
                border: 1px solid var(--c-c);
                @include pc {
                    max-width: 960px;
                    min-width: 960px;
                    @include flex;
                    transform: translateX(-40px);
                }
            }
            @include sp {
                margin-bottom: 0;
                padding-bottom: spw(80);
                background: var(--c-f8);
                .inner {
                    margin: 0 auto;
                    width: spw(335);
                    padding: spw(24) spw(20) spw(48);
                }
            }
            .ttls {
                width: calc(100% - 400px);
                line-height: 1;
                .en {
                    margin-bottom: 20px;
                    font-family: var(--ff-eb);
                    font-size: 44px;
                    display: block;
                }
                .jp {
                    font-weight: 700;
                    font-family: var(--ff-serif);
                    display: block;
                    @include ico-org;
                }
                @include sp {
                    margin-bottom: spw(48);
                    width: 100%;
                    .en {
                        margin-bottom: spw(10);
                        font-size: spw(44);
                    }
                    .jp {
                        padding-left: spw(22);
                    }
                }
            }
            .txt {
                width: 400px;
                @include sp {
                    width: 100%;
                }
            }
        }
        .about, .whats {
            margin-bottom: 104px;
            .inner {
                @include pc {
                    max-width: 1000px;
                }
                .imgs {
                    margin-bottom: 80px;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                    &.double {
                        margin-bottom: 80px;
                        @include flex;
                        gap: 45px;
                        img {
                            width: 480px;
                            height: auto;
                            object-fit: cover;
                        }
                    }
                }
                .cols {
                    @include flex($v: flex-start);
                }
                .ttls {
                    width: calc(100% - 640px);
                    line-height: 1;
                    .en {
                        margin-bottom: 16px;
                    }
                    .jp {
                        position: relative;
                        @include ico-org;
                    }
                }
                .txts {
                    width: 640px;
                }
            }
            @include sp {
                margin-bottom: 0;
                padding-bottom: spw(64);
                .inner {
                    padding: 0;
                    width: 100%;
                    .imgs {
                        margin-bottom: spw(48);
                        &.double {
                            margin-bottom: spw(48);
                            gap: spw(15);
                            img {
                                width: spw(180);
                            }
                        }
                    }
                    .cols {
                        padding: 0 spw(20);
                        flex-direction: column;
                        gap: spw(48);
                    }
                    .ttls {
                        width: 100%;
                        .en {
                            margin-bottom: spw(10);
                        }
                        .jp {
                            @include ico-org;
                        }
                    }
                    .txts {
                        width: 100%;
                    }
                }
            }
        }
        .whats {
            // margin-bottom: 188px;
            position: relative;
            &::before {
                @include content;
                // width: pcw(1320);
                width: calc(100vw - ((100vw - 1000px) / 2 / 2));
                height: 480px;
                background: var(--c-f8);
                top: 160px;
                right: 0;
            }
            @include sp {
                &::before {
                    width: 100%;
                    height: calc(100% - spw(60));
                    top: spw(60);
                }
            }
        }
        .viewmore {
            margin-top: -104px;
            padding-top: 156px;
            max-width: initial;
            position: relative;
            &::before {
                @include content;
                width: 100%;
                height: 580px;
                background: var(--c-e2);
                z-index: -1;
                top: 0;
                left: 0;
            }
            @include sp {
                margin-top: 0;
                padding-top: spw(64);
                &::before {
                    height: spw(360);
                }
                .inner {
                    margin: 0;
                    padding: 0;
                    width: 100%;
                }
            }
            // .more {
            //     margin-right: auto;
            //     margin-left: auto;
            //     padding-left: 20px;
            //     text-align: right;
            //     width: 200px;
            //     font-size: 16px;
            //     border-bottom: 1px solid var(--c-blk);
            //     position: relative;
            //     display: block;
            //     @include arrow-r($w: 16, $h: 6, $c: --c-blk);
            //     &::after {
            //         border-right: none;
            //         border-left: 1px solid var(--c-blk);
            //         right: initial;
            //         left: 5px;
            //         transform: skewX(-45deg);
            //     }
            //     @include sp {
            //         width: spw(255);
            //         font-size: spw(16);
            //     }
            // }
        }
    }
    &-client {
        &.fixbg {
            &::before {
                background-image: url(../img/client/bg-ttl-pc.png);
                @include sp {
                    background-image: url(../img/client/bg-ttl-sp.png);
                }
            }
        }
        .intro {
            margin-bottom: -158px;
            padding: 80px 0 278px;
            background: var(--c-f8);
            .wrap {
                margin: 0 auto;
                max-width: 920px;
                @include flex($v: flex-start);
            }
            .txts {
                width: 600px;
                .category {
                    margin-bottom: 5px;
                    padding-left: 22px;
                    font-size: 14px;
                    font-weight: 400;
                    position: relative;
                    &::before {
                        @include content;
                        width: 12px;
                        height: 12px;
                        background: var(--c-org);
                        border-radius: 50%;
                        top: calc(50% - 6px);
                        left: 0;
                    }
                }
                .ttl {
                    margin-bottom: 30px;
                    font-size: 24px;
                    font-weight: 700;
                    font-family: var(--ff-serif);
                }
                .company {
                    margin-bottom: 20px;
                    font-size: 14px;
                    a {
                        color: var(--c-8);
                        text-decoration: underline;
                    }
                }
                .tag {
                    font-size: 14px;
                    font-weight: 400;
                    li {
                        padding-left: 16px;
                        font-size: 18px;
                        position: relative;
                        &::before {
                            @include content;
                            width: 6px;
                            height: 6px;
                            background: var(--c-org);
                            border-radius: 50%;
                            top: calc(50% - 3px);
                            left: 0;
                        }
                    }
                }
            }
            .imgs {
                width: 300px;
                height: 300px;
                background: var(--c-wht);
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            @include sp {
                margin-bottom: spw(-109);
                padding: spw(16) 0 spw(157);
                .wrap {
                    max-width: spw(335);
                    flex-direction: column-reverse;
                    gap: spw(20);
                }
                .txts {
                    width: 100%;
                    .category {
                        padding-left: spw(22);
                        font-size: spw(14);
                        &::before {
                            width: spw(12);
                            height: spw(12);
                            top: calc(50% - spw(6));
                        }
                    }
                    .ttl {
                        margin-bottom: spw(30);
                        font-size: spw(24);
                    }
                    .company {
                        margin-bottom: spw(20);
                        font-size: spw(14);
                    }
                    .tag {
                        font-size: spw(14);
                        li {
                            padding-left: spw(16);
                            font-size: spw(14);
                            &::before {
                                width: spw(6);
                                height: spw(6);
                                top: calc(50% - spw(3));
                            }
                        }
                    }
                }
                .imgs {
                    width: spw(120);
                    height: spw(120);
                }
            }
        }
        .issue {
            margin: 0 auto 60px;
            padding: 48px 80px;
            max-width: 1080px;
            background: var(--c-wht);
            .ttls {
                margin-bottom: 64px;
                padding-bottom: 22px;
                text-align: center;
                border-bottom: 1px solid var(--c-c);
                .en {
                    font-size: 44px;
                    font-family: var(--ff-eb);
                    font-weight: 400;
                }
                .jp {
                    font-size: 14px;
                    font-family: var(--ff-serif);
                    font-weight: 700;
                }
            }
            .lead {
                margin-bottom: 56px;
                font-size: 22px;
                font-family: var(--ff-serif);
                font-weight: 400;
                text-align: center;
            }
            .flow {
                @include flex($v: flex-start);
                position: relative;
                &::after {
                    @include content;
                    width: 90%;
                    height: 1px;
                    background: var(--c-org);
                    top: 50%;
                    left: 5%;
                }
                .card {
                    padding: 25px 20px 24px;
                    width: calc(100% / 3 - pcw(30));
                    border: 1px solid var(--c-e);
                    background: var(--c-wht);
                    box-shadow: 6px 6px 8px 0 rgba(204, 204, 204, .2);
                    align-self: stretch;
                    position: relative;
                    z-index: 1;
                    &:nth-child(2) {
                        border-color: var(--c-org);
                        box-shadow: 6px 6px 8px 0 rgba(250, 145, 61, .1);
                    }
                    &::before {
                        @include content;
                        width: 16px;
                        height: 16px;
                        background: var(--c-org);
                        border-radius: 50%;
                        top: -8px;
                        left: calc(50% - 8px);
                    }
                    &:not(:last-child) {
                        &::after {
                            @include content;
                            width: 24px;
                            height: 24px;
                            border-top: 1px solid var(--c-org);
                            border-right: 1px solid var(--c-org);
                            transform: rotate(45deg);
                            top: calc(50% - 12px);
                            right: -24px;
                        }
                    }
                    h3 {
                        margin-bottom: 46px;
                        text-align: center;
                        .en {
                            margin-bottom: 4px;
                            padding-bottom: 4px;
                            font-size: 44px;
                            font-weight: 500;
                            font-family: var(--ff-eb);
                            display: block;
                            position: relative;
                            &::after {
                                @include content;
                                width: 20px;
                                height: 4px;
                                background: var(--c-c);
                                left: calc(50% - 10px);
                                bottom: 4px;
                            }
                        }
                        .jp {
                            font-size: 18px;
                            font-weight: 700;
                            font-family: var(--ff-serif);
                            display: block;
                        }
                    }
                }
            }
            @include sp {
                margin-bottom: spw(48);
                padding: spw(32) 0 0;
                width: spw(335);
                .ttls {
                    margin: 0 spw(20) spw(64);
                    padding-bottom: spw(22);
                    .en {
                        font-size: spw(33);
                    }
                    .jp {
                        font-size: spw(14);
                    }
                }
                .lead {
                    margin-bottom: spw(28);
                    font-size: spw(24);
                    text-align: left;
                    br {
                        display: none;
                    }
                }
                .flow {
                    flex-direction: column;
                    &::after {
                        width: 1px;
                        height: 90%;
                        top: 5%;
                        left: 50%;
                    }
                    .card {
                        margin-bottom: spw(32);
                        padding: spw(40) spw(20) spw(32);
                        width: 100%;
                        &:not(:last-child) {
                            &::after {
                                width: spw(18);
                                height: spw(18);
                                transform: rotate(135deg);
                                top: initial;
                                right: calc(50% - spw(9));
                                bottom: spw(-18);
                            }
                        }
                        h3 {
                            margin-bottom: spw(46);
                            .en {
                                margin-bottom: spw(4);
                                padding-bottom: spw(4);
                                font-size: spw(44);
                                &::after {
                                    width: spw(20);
                                    height: spw(4);
                                    left: calc(50% - spw(10));
                                    bottom: spw(4);
                                }
                            }
                            .jp {
                                font-size: spw(18);
                            }
                        }
                    }
                }
            }
        }
        .voicelist.more {
            position: relative;
            overflow: hidden;
            &::before {
                @include content;
                width: 100%;
                height: 406px;
                background: var(--c-f8);
                top: 0;
            }
            @include sp {
                background: var(--c-f8);
            }
        }
    }
    &-voice {
        &.fixbg {
            &::before {
                background-image: url(../img/voice/bg-ttl-pc.png);
                @include sp {
                    background-image: url(../img/voice/bg-ttl-sp.png);
                }
            }
        }
        .detail {
            margin-bottom: 120px;
            position: relative;
            &::before {
                @include content;
                width: calc(100vw - ((100vw - 1240px) / 2));
                height: 100%;
                background: var(--c-f8);
                top: 0;
                left: 0;
                z-index: 2;
            }
            &::after {
                @include content;
                width: calc(100vw - ((100vw - 1360px) / 2));
                height: calc(100% - 300px);
                background: var(--c-c);
                top: 340px;
                right: 0;
                z-index: 1;
            }
            @media screen and (max-width: 1350px) {
                &::before, &::after {
                    width: 95vw;
                }
            }
            @include sp {
                &::before {
                    display: none;
                }
                &::after {
                    width: spw(355);
                    height: 100%;
                    top: spw(24);
                }
            }
            .wrap {
                margin: 0 auto;
                max-width: 920px;
                padding: 96px 0 120px;
                position: relative;
                z-index: 3;
                @include sp {
                    padding: 0 0 spw(64);
                    background: var(--c-f8);
                }
            }
            .intro {
                @include flex($v: flex-start);
                .txts {
                    width: 440px;
                    .number {
                        margin-bottom: 10px;
                        font-size: 44px;
                        font-family: var(--ff-eb);
                        &::before {
                            content: "Vol.";
                            font-size: 24px;
                        }
                    }
                    h2 {
                        margin-left: 54px;
                        @include flex($v: flex-start);
                        flex-direction: column;
                        .company {
                            margin-bottom: 10px;
                            padding-bottom: 8px;
                            font-size: 24px;
                            font-weight: 500;
                            font-family: var(--ff-serif);
                            border-bottom: 1px solid var(--c-c);
                            position: relative;
                        }
                        a.company {
                            padding-right: 34px;
                            &::before {
                                @include content;
                                width: 24px;
                                height: 24px;
                                background: var(--c-c);
                                border-radius: 50%;
                                top: calc(50% - 12px);
                                right: 0;
                            }
                            &::after {
                              @include content;
                              width: 12px;
                              height: 4px;
                              border-right: 1px solid var(--c-wht);
                              border-bottom: 1px solid var(--c-wht);
                              right: 8px;
                              bottom: 24px;
                              transform: skewX(45deg);
                            }
                        }
                        .name {
                            margin-bottom: 5px;
                            font-size: 33px;
                            font-weight: 700;
                            font-family: var(--ff-serif);
                        }
                    }
                    .post {
                        margin-left: 50px;
                        font-size: 14px;
                        display: inline-block;
                    }
                }
                .imgs {
                    width: 460px;
                    transform: translateY(-140px);
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
                @include sp {
                    flex-direction: column-reverse;
                    .txts {
                        padding: spw(20) 0;
                        width: 100%;
                        .number {
                            padding: 0 spw(20);
                            font-size: spw(24);
                            &::before {
                                font-size: spw(24);
                            }
                        }
                        h2 {
                            margin-left: 0;
                            @include flex($v: flex-start);
                            flex-direction: column;
                            .company {
                                padding-right: spw(54);
                                padding-left: spw(20);
                                width: 100%;
                                font-size: spw(18);
                                @include flex;
                                &::before {
                                    width: spw(24);
                                    height: spw(24);
                                    top: calc(50% - spw(12));
                                    right: spw(20);
                                }
                                &::after {
                                  width: spw(12);
                                  height: spw(4);
                                  right: spw(28);
                                  bottom: spw(20);
                                }
                                span {
                                    font-size: spw(12);
                                }
                            }
                            .name {
                                padding: 0 spw(20);
                                font-size: spw(24);
                            }
                        }
                        .post {
                            margin-left: spw(10);
                            font-size: spw(14);
                        }
                    }
                    .imgs {
                        width: 100%;
                        transform: translateY(0);
                    }
                }
            }
            .block {
                padding: 56px 0 66px;
                &:not(:last-child) {
                    border-bottom: 1px solid var(--c-c);
                }
                @include flex($v: flex-start);
                .ttls {
                    margin-bottom: spw(38);
                    width: 340px;
                    .en {
                        margin-bottom: 5px;
                    }
                    .jp {
                        font-size: 14px;
                    }
                }
                .txts {
                    width: 520px;
                }
                @include sp {
                    margin: 0 spw(20);
                    padding: spw(48) 0;
                    flex-direction: column;
                    .ttls {
                        width: 100%;
                        .jp {
                            font-size: spw(14);
                        }
                    }
                    .txts {
                        width: 100%;
                    }
                }
            }
        }
    }
    &-news {
        .head {
            height: 420px;
            color: var(--c-blk);
        }
        .intro {
            margin-bottom: 80px;
            background: var(--c-f8);
            .inner {
                padding: 80px 0;
                max-width: 920px;
                min-width: initial;
                @include flex($v: flex-start);
                &:has(:not(.imgs)) .txts {
                    max-width: 100%;
                }
            }
            .txts {
                max-width: 460px;
                .category {
                    margin-bottom: 5px;
                    padding-left: 22px;
                    font-size: 14px;
                    font-weight: 400;
                    position: relative;
                    &::before {
                        @include content;
                        width: 12px;
                        height: 12px;
                        background: var(--c-org);
                        border-radius: 50%;
                        top: calc(50% - 6px);
                        left: 0;
                    }
                }
                h2 {
                    margin-bottom: 48px;
                    font-size: 24px;
                }
                .date {
                    font-size: 18px;
                }
            }
            .imgs {
                max-width: 400px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            @include sp {
                margin-bottom: spw(48);
                .inner {
                    padding: spw(20);
                    max-width: initial;
                    flex-direction: column-reverse;
                    gap: spw(20);
                }
                .txts {
                    width: 100%;
                    .category {
                        margin-bottom: spw(5);
                        padding-left: spw(22);
                        font-size: spw(14);
                        &::before {
                            width: spw(12);
                            height: spw(12);
                            top: calc(50% - spw(6));
                        }
                    }
                    h2 {
                        margin-bottom: spw(48);
                        font-size: spw(24);
                    }
                    .tag {
                        font-size: spw(16);
                        gap: spw(10);
                    }
                }
                .imgs {
                    width: 100%;
                }
            }
        }
    }
    &-recruit {
        &.fixbg {
            &::before {
                background-image: url(../img/recruit/bg-ttl-pc.png);
                @include sp {
                    background-image: url(../img/recruit/bg-ttl-sp.png);
                }
            }
        }
        .intro {
            padding: 107px 0;
            max-width: 1020px;
            min-width: initial;
            font-family: var(--ff-serif);
            @include flex;
            h3 {
                margin-bottom: spw(40);
                width: 400px;
                font-size: 33px;
                font-weight: 700;
            }
            .txts {
                width: 450px;
                font-size: 18px;
            }
            @include sp {
                padding: spw(40) spw(20) spw(116);
                flex-direction: column;
                h3 {
                    width: 100%;
                    font-size: spw(33);
                }
                .txts {
                    width: 100%;
                    font-size: spw(18);
                }
            }
        }
        .content {
            padding-bottom: 120px;
            @include sp {
                padding-bottom: spw(104);
            }
        }
        &:has(.detail) {
            .breadcrumb {
                position: static;
            }
            .head {
                padding: 80px 0;
                color: var(--c-blk);
                text-align: left;
                background: var(--c-f8);
                .inner {
                    width: 920px;
                    min-width: initial;
                }
                h2 {
                    font-size: 33px;
                    font-family: var(--ff-serif);
                    font-weight: 700;
                    span {
                        margin-bottom: 5px;
                        padding-left: 22px;
                        font-size: 14px;
                        font-weight: 400;
                        font-family: var(--ff-sans);
                        position: relative;
                        display: block;
                        &::before {
                            @include content;
                            width: 12px;
                            height: 12px;
                            background: var(--c-org);
                            border-radius: 50%;
                            top: calc(50% - 6px);
                            left: 0;
                        }
                    }
                }
            }
            @include sp {
                .head {
                    padding: spw(40) spw(24) spw(34);
                    height: initial;
                    .inner {
                        width: 100%;
                    }
                    h2 {
                        font-size: spw(24);
                        span {
                            margin-bottom: spw(5);
                            padding-left: spw(22);
                            font-size: spw(14);
                            &::before {
                                width: spw(12);
                                height: spw(12);
                                top: calc(50% - spw(6));
                            }
                        }
                    }
                }
            }
        }
        .detail {
            max-width: 920px;
            min-width: initial;
            dl {
                padding: 83px 0 120px;
            }
            .link-next {
                width: 280px;
            }
            @include sp {
                padding: 0 spw(10);
                width: 100%;
                dl {
                    padding: spw(48) 0 spw(80);
                }
                .link-next {
                    width: spw(255);
                }
            }
        }
    }
    &-company {
        &.fixbg {
            &::before {
                background-image: url(../img/company/bg-ttl-pc.png);
                @include sp {
                    background-image: url(../img/company/bg-ttl-sp.png);
                }
            }
        }
        .head {
            .en {
                margin-bottom: 10px;
            }
        }
        .intro {
            margin: 0 auto 80px;
            padding-top: 170px;
            width: 920px;
            @include flex($v: flex-start);
            .ttls {
                width: 340px;
            }
            .txts {
                width: 520px;
                dt {
                    margin-bottom: 48px;
                    font-size: 33px;
                    font-weight: 700;
                    font-family: var(--ff-serif);
                }
            }
            @include sp {
                margin-bottom: spw(64);
                padding-top: spw(93);
                width: spw(335);
                flex-direction: column;
                .ttls {
                    margin-bottom: spw(37);
                    width: 100%;
                }
                .txts {
                    width: 100%;
                    dt {
                        margin-bottom: spw(40);
                        font-size: spw(33);
                    }
                }
            }
        }
        .information {
            padding: 48px 0;
            position: relative;
            &::after {
                @include content;
                width: calc(920px + ((100vw - 920px) / 2) + 80px);
                height: calc(100% - 104px);
                background: var(--c-f8);
                top: 0;
                right: 0;
            }
            .inner {
                margin: 0 auto 104px;
                padding: 0;
                width: 920px;
                min-width: initial;
                position: relative;
                z-index: 1;
                @include flex($v: flex-start);
                .ttls {
                    width: 340px;
                }
                .txts {
                    width: 520px;
                }
            }
            @include sp {
                padding: spw(48) spw(10);
                &::after {
                    display: none;
                }
                .inner {
                    margin: 0;
                    width: 100%;
                    flex-direction: column;
                    .ttls {
                        margin: 0 spw(10);
                        width: 100%;
                    }
                    .txts {
                        width: 100%;
                    }
                }
            }
        }
    }
    &-contact {
        .head {
            color: var(--c-blk);
            border-bottom: 1px solid var(--c-c);
        }
        .notice {
            margin: 0 auto;
            padding: 30px 0;
            max-width: 700px;
            font-weight: 500;
        }
        .content {
            padding: 64px 0 120px;
            background: var(--c-f8);
        }
        .inner {
            margin: 0 auto;
            padding: 48px 80px;
            max-width: 920px;
            background: var(--c-wht);
            border-radius: 12px;
            h3 {
                margin-bottom: 40px;
                font-size: 18px;
                font-weight: 500;
                text-align: center;
            }
            dl {
                margin-bottom: 40px;
                @include flex($v: flex-start);
                gap: 40px 20px;
                dt {
                    padding-top: 20px;
                    padding-right: 60px;
                    width: 220px;
                    &.required {
                        position: relative;
                        &::after {
                            content: "必須";
                            display: block;
                            padding: 2px 4px;
                            max-width: 50px;
                            height: 1;
                            line-height: 22px;
                            font-size: 14px;
                            font-weight: 500;
                            text-align: center;
                            color: #FA3D3D;
                            background: #FFF2F2;
                            border-radius: 4px;
                            position: absolute;
                            top: calc(50% - 4px);
                            right: 0;
                        }
                    }    
                }
                .entrycat {
                    .wpcf7-radio {
                        @include flex($v: flex-start);
                        flex-direction: column;
                    }
                }
                dd {
                    width: calc(100% - 240px);
                    &.cols {
                        p {
                            @include flex($h: flex-start);
                            gap: 10px;
                        }
                    }
                }
            }
            .policy {
                margin: 0 auto 32px;
                max-width: 600px;
                h4 {
                    margin-bottom: 20px;
                    font-size: 14px;
                    font-weight: 500;
                    text-align: center;
                }
                .frame {
                    padding: 20px;
                    width: 100%;
                    height: 300px;
                    overflow-y: scroll;
                    border: 1px solid var(--c-c);
                    border-radius: 4px;
                }
            }
            .agree-check {
                margin-bottom: 80px;
                text-align: center;
                font-weight: 500;
            }
            .link-next {
                input[type=submit] {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
        @include sp {
            .notice {
                padding: spw(20);
                max-width: initial;
            }
            .content {
                padding: spw(64) 0 spw(120);
            }
            .inner {
                padding: spw(35) spw(25);
                max-width: spw(355);
                h3 {
                    margin-bottom: spw(40);
                    font-size: spw(18);
                }
                dl {
                    margin-bottom: spw(40);
                    flex-direction: column;
                    gap: 0;
                    dt {
                        margin-bottom: 10px;
                        padding-right: spw(60);
                        width: 100%;
                        &.required {
                            &::after {
                                content: "必須";
                                font-size: spw(14);
                            }
                        }    
                    }
                    dd {
                        margin-bottom: spw(40);
                        width: 100%;
                    }
                }
                .policy {
                    margin-bottom: spw(32);
                    h4 {
                        margin-bottom: spw(20);
                        font-size: spw(14);
                    }
                    .frame {
                        padding: spw(20);
                        height: spw(300);
                    }
                }
                .agree-check {
                    margin-bottom: spw(80);
                }
            }
        }
        .complate {
            text-align: center;
            p {
                margin-bottom: 40px;
                line-height: 2;
            }
        }    
    }
}
.wpcf7-response-output {
    display: none;
}