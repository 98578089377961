@mixin sp($w: $bp) {
	@media screen and (max-width: $w) {
		@content;
	}
}
@mixin pc($w: $bp) {
	@media screen and (min-width: $w + 1) {
		@content;
	}
}
@mixin flex($wrap: wrap, $h: space-between, $v: center) {
	display: flex;
	flex-wrap: $wrap;
	justify-content: $h;
	align-items: $v;
}
@mixin ts($property: all, $duration: .3s, $delay: 0s) {
	transition: $property $duration $delay;
}
@mixin content($position: absolute) {
	content: "";
	display: block;
	position: $position;
}
@mixin ico-arrow {
	padding-right: 34px;
	position: relative;
	&::before {
		@include content;
		width: 24px;
		height: 24px;
		background: var(--c-blk);
		border-radius: 50%;
		top: calc(50% - 12px);
		right: 0;
		@include ts;
	}
	span {
		&::before, &::after {
			@include content;
			width: 8px;
			height: 3px;
			border-right-width: 1px;
			border-right-style: solid;
			border-bottom-width: 1px;
			border-bottom-style: solid;
			transform: skewX(45deg);
			top: calc(50% - 3px);
			@include ts;
		}
		&::before {
			border-right-color: var(--c-wht);
			border-bottom-color: var(--c-wht);
			right: 8px;
		}
		&::after {
			border-right-color: var(--c-blk);
			border-bottom-color: var(--c-blk);
			right: 20px;
			opacity: 0;
		}
	}
	@include pc {
		&:hover {
			&::before {
				background: var(--c-wht);
			}
			span {
				&::before {
					right: -32px;
					opacity: 0;
				}
				&::after {
					right: 8px;
					opacity: 1;
				}
			}
		}
	}
	@include sp {
		padding-right: spw(34);
		&::before {
			width: spw(24);
			height: spw(24);
			top: calc(50% - spw(12));
		}
		&::after {
			width: spw(8);
			height: spw(3);
			top: calc(50% - spw(3));
			right: spw(8);
		}
	}
}
@mixin arrow-r($w: 8, $h: 3, $c: --c-wht) {
	&::after {
		@include content;
		width: calc($w * 1px);
		height: calc($h * 1px);
		border-right: 1px solid var($c);
		border-bottom: 1px solid var($c);
		transform: skewX(45deg);
		top: calc(50% - ($h * 1px));
		right: 5px;
	}
	@include sp {
		&::after {
			width: spw($w);
			height: spw($h);
			top: calc(50% - spw($h));
			right: spw($w);
		}
	}
}
@mixin ico-org {
	padding-left: 22px;
	position: relative;
	&::before {
		@include content;
		width: 12px;
		height: 12px;
		background: var(--c-org);
		border-radius: 50%;
		top: calc(50% - 6px);
		left: 0;
	}
	@include sp {
		padding-left: spw(22);
		&::before {
			width: spw(12);
			height: spw(12);
			top: calc(50% - spw(6));
		}
	}
}