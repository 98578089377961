.inner {
  margin-right: auto;
  margin-left: auto;
  padding: 0 40px;
  height: 100%;
  position: relative;
  @include pc {
    max-width: 1360px;
    min-width: 1130px;
  }
  @include sp {
    padding: 0 spw(20);
    // width: calc(100% - spw(40));
    width: 100%;
  }
}
.fixbg {
  position: relative;
  &::before {
    @include content;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
    z-index: -1;
  }
  .breadcrumb {
    position: absolute;
    top: 400px;
  }
  .head {
    // & + .wrap {
    //   background: var(--c-wht);
    // }
  }
  @include sp {
    .breadcrumb {
      position: static;
    }
  }
}
.head {
  height: 320px;
  line-height: 1.2;
  text-align: center;
  color: var(--c-wht);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  @include flex($h: center);
  .en {
    font-size: 78px;
    font-family: var(--ff-eb);
    display: block;
  }
  .jp {
    font-size: 18px;
    font-family: var(--ff-serif);
    display: block;
  }
}
.breadcrumb {
  padding: 32px;
  width: 200px;
  font-size: 12px;
  &.wide {
    width: 100%;
  }
  z-index: 1;
  a {
    color: var(--c-8);
  }
  @include sp {
    padding-top: spw(10);
    padding-bottom: spw(10);
    width: 100%;
    height: initial;
    background: var(--c-wht);
    border-top: 1px solid var(--c-e);
    border-bottom: 1px solid var(--c-e);
    position: static;
    ul {
      @include flex($h: center);
    }
  }
}
.pagettl {
  margin-bottom: 64px;
  height: 128px;
  font-size: 24px;
  font-family: var(--ff-serif);
  font-weight: 700;
  text-align: center;
  border-bottom: 1px solid var(--c-e);  
  @include flex($h: center);
  @include sp {
    border-bottom: none;
  }
}
.ttls {
  .en {
    line-height: 1.2;
    font-size: 58px;
    font-family: var(--ff-eb);
    display: block;
  }
  .jp {
    line-height: 1.2;
    font-size: 18px;
    font-family: var(--ff-serif);
    font-weight: 700;
    display: block;
  }
  &.circle {
    .jp {
        padding-left: 20px;
        position: relative;
        &::before {
          @include content;
          width: 12px;
          height: 12px;
          background: var(--c-org);
          border-radius: 50%;
          top: calc(50% - 6px);
          left: 0;
        }
    }
  }
  @include sp {
    .en {
        margin-bottom: 0;
        font-size: spw(58);
    }
    .jp {
        font-size: spw(18);
    }
  }
}
.stafflist {
  overflow: auto;
  .list {
    margin: 0 auto 80px;
    max-width: 1160px;
  }
  // @include pc {
  //   margin: 0 auto;
  //   max-width: 1320px;
  // }
  .ttls {
    margin-bottom: 48px;
    line-height: 1;
    text-align: center;
    .en {
      margin-bottom: 5px;
      font-size: 44px;
      font-family: var(--ff-eb);
      display: block;
    }
    .jp {
      padding-top: 10px;
      font-size: 18px;
      font-family: var(--ff-serif);
      font-weight: 700;
      display: block;
      position: relative;
      &::before {
        @include content;
        width: 20px;
        height: 4px;
        background: var(--c-c);
        top: 0;
        left: calc(50% - 10px);
      }
    }
    @include sp {
      margin-bottom: spw(48);
      .en {
        margin-bottom: spw(10);
        font-size: spw(33);
      }
      .jp {
        font-size: spw(18);
      }
    }
  }
  &.large {
    .list {
      @include flex($h: flex-start);
      gap: 46px;
      li {
        width: calc(100% / 3 - 31px);
      }
      .txts {
        padding-top: 20px;
        padding-bottom: 17px;
        position: relative;
        @include flex;
        &::before {
          @include content;
          width: 16px;
          height: 4px;
          border-right: 1px solid var(--c-blk);
          border-bottom: 1px solid var(--c-blk);
          right: 10px;
          bottom: 15px;
          transform: skewX(45deg);
        }
        &::after {
          @include content;
          width: 100%;
          height: 36px;
          border-right: 1px solid var(--c-e);
          border-bottom: 1px solid var(--c-e);
          pointer-events: none;
          right: 0;
          bottom: 0;
        }
      }
      .imgs {
        width: 356px;
        height: 264px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    @include sp {
      padding: 0 spw(10);
      .list {
        flex-direction: column;
        gap: spw(40) 0;
        li {
          width: 100%;
        }
        .txts {
          margin: 0 spw(10);
          &::before {
            width: spw(16);
            height: spw(4);
            right: spw(10);
            bottom: spw(15);
          }
          &::after {
            height: spw(36);
          }
        }
        .imgs {
          width: spw(355);
          height: spw(266);
        }
      }
    }
  }
  &.small {
    margin-top: 105px;
    padding-top: 40px;
    border-top: 1px solid var(--c-c);
    .list {
      @include flex($h: flex-start);
      gap: 44px;
      li {
        width: calc((100% / 4) - 33px);
      }
    }
    .imgs {
      width: 257px;
      height: 192px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    @include sp {
      margin: spw(80) spw(20) 0;
      padding-top: spw(24);
      .list {
        gap: spw(24) spw(15);
        li {
          width: calc((100% / 2) - spw(8));
        }
        .txts {
          padding: spw(8) 0;
        }
        .imgs {
          width: spw(160);
          height: spw(120);
        }
      }
    }
  }
  &.viewmore {
    .list {
      @include flex($h: flex-start);
      gap: 46px;
      li {
        width: calc(100% / 3 - 31px);
      }
      .txts {
        padding-top: 20px;
        padding-bottom: 17px;
        position: relative;
        @include flex;
        &::before {
          @include content;
          width: 16px;
          height: 4px;
          border-right: 1px solid var(--c-blk);
          border-bottom: 1px solid var(--c-blk);
          right: 10px;
          bottom: 15px;
          transform: skewX(45deg);
        }
        &::after {
          @include content;
          width: 100%;
          height: 36px;
          border-right: 1px solid var(--c-e);
          border-bottom: 1px solid var(--c-e);
          pointer-events: none;
          right: 0;
          bottom: 0;
        }
      }
    }
    @include sp {
      padding: 0;
      .list {
        display: block;
        li {
          width: 100%;
        }
        .txts {
          margin: 0 spw(10);
          &::before {
            width: spw(16);
            height: spw(4);
            right: spw(10);
            bottom: spw(15);
          }
          &::after {
            height: spw(36);
          }
        }
      }
    }
  }
  ul {
    .imgs {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .jp {
        width: 50%;
        font-size: 18px;
        font-weight: 700;
        font-family: var(--ff-eb);
    }
    // .en {
    //     width: 50%;
    //     font-size: 12px;
    //     font-family: var(--ff-serif);
    //     text-align: right;
    //     color: var(--c-8);
    // }
    .en {
      font-size: 18px;
      font-family: var(--ff-eb);
    }
    .position {
        width: 100%;
        font-size: 12px;
        font-family: var(--ff-sans);
        color: var(--c-8);
    }
    @include sp {
      margin-bottom: spw(48);
      // li {
      //     padding: 0 spw(10);
      //     width: spw(295);
      // }
      .txts {
        padding-top: spw(16);
        padding-bottom: spw(16);
        &::after {
          height: 1px;
          border-right: none;
        }
      }
      .jp {
        // width: 100%;
        font-size: spw(18);
      }
      .en {
        font-size: spw(12);
        // position: absolute;
        // right: 0;
        // bottom: spw(16);
      }
      .position {
        // width: 50%;
        font-size: spw(12);
      }
    }
  }
}
.voicelist {
  overflow: auto;
  .ttls {
    margin-bottom: 56px;
    text-align: center;
    .en {
      font-size: 33px;
      font-weight: 700;
    }
    .jp {
      font-size: 18px;
    }
  }
  .list {
    margin: 0 auto 80px;
    max-width: 1160px;
    @include flex($h: flex-start);
    gap: 56px 48px;
    & > li {
      width: calc(100% / 4 - 36px);
      align-self: stretch;
    }
    .num {
      font-size: 18px;
      font-family: var(--ff-eb);
    }
    .imgs {
      width: 254px;
      height: 254px;
      position: relative;
      @include pc {
        &::after {
          @include content;
          width: 100%;
          height: 100%;
          background: var(--c-org);
          opacity: 0;
          top: 0;
          left: 0;
          @include ts;
        }
        &:hover {
          &::after {
            opacity: 0.6;
          }
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .txts {
      padding-top: 20px;
      padding-bottom: 17px;
      border-bottom: 1px solid var(--c-e);
      position: relative;
      &::before {
        @include content;
        width: 16px;
        height: 4px;
        border-right: 1px solid var(--c-blk);
        border-bottom: 1px solid var(--c-blk);
        right: 10px;
        bottom: 15px;
        transform: skewX(45deg);
      }
    }
    .company {
      margin-bottom: 10px;
    }
  }
  &.more {
      margin-bottom: 104px;
      padding-top: 56px;
      .ttls, .list {
          position: relative;
          z-index: 1;
      }
  }
  @include sp {
    .list {
      margin: 0 spw(20) spw(48);
      gap: spw(40) spw(16);
      & > li {
        width: calc(100% / 2 - spw(8));
      }
      .imgs {
        width: spw(160);
        height: spw(160);
      }
      .txts {
        // margin: 0 spw(10);
        &::before {
          width: spw(16);
          height: spw(4);
          right: spw(10);
          bottom: spw(15);
        }
        &::after {
          height: spw(36);
        }
      }
      // .txts {
      //   padding-top: spw(16);
      //   padding-bottom: spw(16);
      //   &::after {
      //     height: 1px;
      //     border-right: none;
      //   }
      // }
      .jp {
        font-size: spw(18);
      }
      .en {
        font-size: spw(12);
      }
      .position {
        font-size: spw(12);
      }
    }
    &.more {
        margin-bottom: spw(48);
        padding: spw(48) spw(20);
        &::before {
            display: none;
        }
        .list {
          margin-right: 0;
          margin-left: 0;
        }
    }
  }
}
.seminarlist {
  .list {
    margin: 0 auto 80px;
    max-width: 1160px;
    @include flex($h: flex-start);
    gap: 64px 52px;
    & > li {
      width: calc(100% / 3 - 35px);
    }
  }
  a {
      .img {
          margin-bottom: 32px;
          width: 100%;
          height: 236px;
          object-fit: cover;
      }
      .txt {
          margin-bottom: 20px;
      }
      .cols {
          @include flex;
      }
      .category {
          width: calc(100% - 80px);
          font-family: var(--ff-serif);
          @include flex;
          li {
              padding: 8px;
              border: 1px solid var(--c-c);
          }
      }
      .date {
          width: 80px;
          font-family: var(--ff-eb);
      }
  }
  &.more {
    .ttls {
      margin-bottom: 56px;
      text-align: center;
      .en {
        font-size: 33px;
      }
      .jp {
        font-size: 18px;
      }
    }
  }
  @include sp {
    .list {
      margin-bottom: spw(80);
      width: spw(335);
      flex-direction: column;
      gap: spw(40);
      & > li {
        width: 100%;
      }
    }
    a {
        .img {
            margin-bottom: spw(24);
            height: spw(225);
        }
        .txt {
            margin: 0 spw(20) spw(16);
        }
        .cols {
          margin: 0 spw(20);
        }
        .category {
            width: calc(100% - spw(80));
            li {
                padding: 4px;
            }
        }
        .date {
            width: spw(80);
        }
    }
  }
}
.clientlist {
  .ttls {
    margin-bottom: 56px;
    text-align: center;
    .en {
      font-size: 33px;
      font-weight: 700;
    }
    .jp {
      font-size: 18px;
    }
  }
  .list {
    margin: 0 auto;
    @include flex($h: flex-start);
    & > li {
      margin: -1px 0 0 -1px;
      width: calc(100% / 6 + 1px);
      height: calc(100vw / 6 + 1px);
      border: 1px solid var(--c-e);
      @include ts;
        @include pc {
          &:not(:has(.no-detail)) {
            &:hover {
              border-color: var(--c-org);
              box-shadow: 8px 8px 8px 0 rgba(204, 204, 204, .4);
              z-index: 1;
            }
          }
        }
      a {
        padding: 50px;
        width: 100%;
        height: 100%;
        @include flex($h: center);
        position: relative;
        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        .category {
          position: absolute;
          left: 10px;
          bottom: 10px;
          li {
            padding: 2px 4px;
            font-size: 12px;
            background: var(--c-e);
            border-radius: 2px;
          }
        }
        .link {
          font-size: 12px;
          border-bottom: 1px solid var(--c-blk);
          position: absolute;
          right: 10px;
          bottom: 10px;
        }
      }
    }
    @include sp {
      & > li {
        width: calc(100% / 2 + 1px);
        height: calc(100vw / 2 + 1px);
      }
    }
  }
  &.more {
      .ttls, .list {
        position: relative;
        z-index: 1;
      }
      .list {
        margin: 0 auto 64px;
        max-width: 1080px;
        justify-content: center;
        gap: 48px;
        & > li {
            width: calc(100% / 4 - 35px);
            @include ts;
            @include pc {
              &:not(:has(.no-detail)) {
                &:hover {
                  box-shadow: 8px 8px 8px 0 rgba(204, 204, 204, .4);
                }
              }
            }
        }
      }
      @include sp {
      .list {
          margin: 0 spw(20) spw(48);
          gap: spw(16);
          & > li {
              width: calc(100% / 2 - spw(14));
              min-height: spw(180);
              }
          }
      }
    }
}
.newslist {
  margin-bottom: 120px;
  .list {
    li {
      padding: 32px 40px;
      &:not(:last-child) {
        border-bottom: 1px solid var(--c-c);
      }
    }
    a {
      position: relative;
      &::before {
        @include content;
        width: 16px;
        height: 4px;
        border-right: 1px solid var(--c-org);
        border-bottom: 1px solid var(--c-org);
        right: 0;
        bottom: 15px;
        transform: skewX(45deg);
      }
      .date {
        font-family: var(--ff-eb);
      }
    }
  }
  @include sp {
    margin-bottom: spw(80);
    .list {
      li {
        padding: spw(24) 0;
      }
      a {
        &::before {
          top: 0;
          bottom: initial;
        }
        .date {
          margin-bottom: 10px;
          display: block;
        }
      }
    }
  }
}
.linklist {
  margin: 0 auto;
  max-width: 1000px;
  .list {
    @include flex($h: center);
    & > li {
      width: 50%;
      background: var(--c-wht);
      border: 1px solid var(--c-e);
      a {
        padding: 44px 24px;
        font-size: 24px;
        font-family: var(--ff-serif);
        display: block;
        position: relative;
        &::before {
            @include content;
            width: 24px;
            height: 24px;
            background: var(--c-blk);
            border-radius: 50%;
            top: calc(50% - 12px);
            right: 24px;
        }
        &::after {
          @include content;
          width: 12px;
          height: 4px;
          border-right: 1px solid var(--c-wht);
          border-bottom: 1px solid var(--c-wht);
          right: 32px;
          bottom: 64px;
          transform: skewX(45deg);
        }
        span {
          font-size: 12px;
        }
      }
    }
  }
  @include sp {
    max-width: spw(335);
    .list {
      flex-direction: column;
      & > li {
        width: 100%;
        a {
          padding: spw(31) spw(16);
          font-size: spw(18);
          &::before {
              width: spw(24);
              height: spw(24);
              top: calc(50% - spw(12));
              right: spw(24);
          }
          &::after {
            width: spw(12);
            height: spw(4);
            right: spw(32);
            bottom: spw(46);
          }
          span {
            font-size: spw(12);
          }
        }
      }
    }
  }
}
.definitionlist {
  @include flex($h: flex-start, $v: flex-start);
  dt, dd {
      padding: 24px 0;
      border-bottom: 1px solid var(--c-c);
      align-self: stretch;
      &:last-of-type {
          border: none;
      }
  }
  dt {
      width: 140px;
      font-weight: 500;
  }
  dd {
      width: calc(100% - 140px);
  }
  @include sp {
    flex-direction: column;
    dt, dd {
        padding: 0 spw(10);
        width: 100%;
    }
    dt {
        margin-bottom: spw(10);
        padding-top: spw(24);
        border: none;
    }
    dd {
        padding-bottom: spw(24);
        &:last-of-type {
            border: none;
        }
    }
  }
}
.postcontent {
  margin: 0 auto 120px;
  max-width: 920px;
  p {
      margin-bottom: 20px;
  }
  @include sp {
    margin-bottom: spw(80);
    width: spw(335);
    p {
        margin-bottom: spw(20);
    }
  }
}
.label {
  &-hex {
    margin: 0 16px;
    padding: 3px 10px;
    background: var(--c-wht);
    border-top: 1px solid var(--c-c);
    border-bottom: 1px solid var(--c-c);
    display: inline-block;
    position: relative;
    &::before, &::after {
      @include content;
      width: 16px;
      height: calc(100% + 2px);
      background: url(../img/bg/label-hex-edge.svg) no-repeat 0 0;
      background-size: 100% 100%;
      top: -1px;
    }
    &::before {
      left: -12px;
    }
    &::after {
      right: -12px;
      transform: rotate(180deg);
    }
    @include sp {
      margin: 0 spw(16);
      padding: spw(3) spw(10);
      &::before, &::after {
        width: spw(16);
      }
      &::before {
        left: spw(-12);
      }
      &::after {
        right: spw(-12);
      }
    }
  }
}
.li {
  &-category {
      margin-bottom: 80px;
      padding: 15px 0;
      background: var(--c-e);
      ul {
          @include flex($h: center);
          gap: 10px;
          a {
              padding: 10px 16px;
              line-height: 1;
              border: 1px solid var(--c-c);
              background: var(--c-wht);
              border-radius: 4px;
              display: inline-block;
              @include pc {
                  &:hover {
                      border-color: var(--c-blk);
                  }
              }
          }
      }
  }
}
.link {
  // &-more {
  //     margin-right: auto;
  //     margin-left: auto;
  //     padding-left: 20px;
  //     text-align: right;
  //     width: 200px;
  //     font-size: 16px;
  //     border-bottom: 1px solid var(--c-blk);
  //     position: relative;
  //     display: block;
  //     @include arrow-r($w: 16, $h: 6, $c: --c-blk);
  //     &::after {
  //         border-right: none;
  //         border-left: 1px solid var(--c-blk);
  //         right: initial;
  //         left: 5px;
  //         transform: skewX(-45deg);
  //     }
  //     @include sp {
  //         width: spw(255);
  //         font-size: spw(16);
  //     }
  // }
  &-more {
    margin-right: auto;
    margin-left: auto;
    padding-left: 20px;
    text-align: right;
    width: 200px;
    font-size: 16px;
    border-bottom: 1px solid var(--c-blk);
    position: relative;
    display: block;
    @include arrow-r($w: 16, $h: 6, $c: --c-blk);
    &::after {
        border-right: none;
        border-left: 1px solid var(--c-blk);
        right: initial;
        left: 5px;
        transform: skewX(-45deg);
    }
    @include sp {
        width: spw(255);
        font-size: spw(16);
    }
    // margin-top: 40px;
    // margin-left: auto;
    // width: 280px;
    // font-size: 16px;
    // border-bottom: 1px solid var(--c-blk);
    // display: block;
    // position: relative;
    // @include arrow-r($c: blk);
  }
  &-next {
    margin-right: auto;
    margin-left: auto;
    padding-right: 20px;
    padding-bottom: 5px;
    text-align: left;
    font-weight: 500;
    width: 200px;
    font-size: 16px;
    position: relative;
    display: block;
    @include arrow-r($w: 16, $h: 6, $c: --c-blk);
    &::before {
      @include content;
      width: 100%;
      height: 1px;
      background: var(--c-blk);
      left: 0;
      bottom: 0;
    }
    @include pc {
      &:hover {
        &::before {
          animation: hvline .6s forwards;
        }
      }
    }
    @include sp {
        width: spw(255);
        font-size: spw(16);
    }
    // margin-top: 40px;
    // margin-left: auto;
    // width: 280px;
    // font-size: 16px;
    // border-bottom: 1px solid var(--c-blk);
    // display: block;
    // position: relative;
    // @include arrow-r($c: blk);
  }
}
.wp-pagenavi {
  padding: 16px 0;
  border-top: 1px solid var(--c-e);
  border-bottom: 1px solid var(--c-e);
  @include flex($h: center);
  gap: 30px;
  .page, span {
    width: 32px;
    height: 32px;
    font-size: 16px;
    font-weight: 700;
    border-radius: 50%;
    @include flex($h: center);
    @include ts;
    @include pc {
      &:hover {
        color: var(--c-wht);
        background: var(--c-8);
      }
    }
  }
  .current {
    color: var(--c-wht);
    background: var(--c-8);
  }
}