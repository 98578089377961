header {
    width: 100%;
    background: var(--c-wht);
    border-bottom: 1px solid var(--c-e);
    position: fixed;
    top: 0;
    left: 0;
    .logo-blk {
        display: block;
    }
    .logo-wht {
        display: none;
    }
    .inner {
        padding: 0 40px;
        @include flex;
    }
    h1 {
        width: 149px;
    }
    @include pc {
        nav {
            & > ul {
                @include flex;
                & > li {
                    position: relative;
                    &:not(:has(.btn)) {
                        & > a {
                            padding: 28px 20px;
                            display: inline-block;
                            cursor: pointer;
                        }
                        &:hover {
                            &::after {
                                @include content;
                                width: calc(100% - 40px);
                                height: 4px;
                                background: var(--c-c);
                                left: 20px;
                                bottom: 0px;
                            }
                            .submenu {
                                opacity: 1;
                                pointer-events: initial;
                            }
                        }
                    }
                }
                .submenu {
                    padding: 12px 20px;
                    height: 44px;
                    background: var(--c-f8);
                    position: absolute;
                    left: 0;
                    bottom: -44px;
                    @include flex($h: flex-start);
                    flex-wrap: nowrap;
                    gap: 16px;
                    @include ts;
                    opacity: 0;
                    pointer-events: none;
                    li {
                        white-space: nowrap;
                    }
                    a {
                        padding-left: 25px;
                        color: var(--c-3);
                        position: relative;
                        &::before {
                          @include content;
                          width: 16px;
                          height: 4px;
                          border-right: 1px solid var(--c-3);
                          border-bottom: 1px solid var(--c-3);
                          top: 8px;
                          left: 0px;
                          transform: skewX(45deg);
                        }
                    }
                }
            }
        }
        .btn {
            width: 120px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            color: var(--c-wht);
            background: var(--c-blk);
            border-radius: 4px;
            display: inline-block;
        }
    }
    @include sp {
        height: spw(60);
        .inner {
            padding: 0 0 0 spw(20);
            h1 {
                width: auto;
                height: spw(28);
            }
            .btn-hamburger {
                width: spw(60);
                height: spw(60);
                background: var(--c-e);
                position: relative;
                span, &::before, &::after {
                    @include content;
                    width: spw(24);
                    height: 1px;
                    background: var(--c-8);
                    left: spw(18);
                    transform-origin: left center;
                    @include ts;
                }
                &::before {
                    top: spw(22);
                }
                span {
                    top: 50%;
                }
                &::after {
                    bottom: spw(22);
                }
                &.is-show {
                    &::before {
                        top: spw(21);
                        left: spw(20);
                        transform: rotate(45deg);
                    }
                    span {
                        opacity: 0;
                    }
                    &::after {
                        bottom: spw(21);
                        left: spw(20);
                        transform: rotate(-45deg);
                    }
                }
            }
            .spmenu {
                height: calc(100vh - spw(60));
                overflow-y: scroll;
            }
            nav {
                width: 100%;
                background: var(--c-wht);
                position: absolute;
                top: spw(60);
                left: 0;
                ul {
                    // padding-bottom: spw(72);
                    border-bottom: 1px solid var(--c-e);
                    // flex-direction: column;
                    gap: 0;
                    li {
                        &:not(:has(.btn)){
                            width: 100%;
                        }
                    }
                    .ttl {
                        padding: spw(5) spw(20);
                        position: relative;
                        &::before {
                            @include content;
                            width: spw(24);
                            height: spw(24);
                            background: var(--c-blk);
                            border-radius: 50%;
                            top: calc(50% - spw(12));
                            right: spw(20);
                        }
                        &::after {
                            @include content;
                            width: spw(8);
                            height: spw(3);
                            border-right: 1px solid var(--c-wht);
                            border-bottom: 1px solid var(--c-wht);
                            transform: skewX(45deg);
                            top: spw(18);
                            right: spw(28);
                        }
                    }
                    a {
                        padding: spw(5) spw(20);
                        display: block;
                        font-size: spw(18);
                        font-family: var(--ff-serif);
                        & + ul {
                            a {
                                padding-left: spw(40);
                                font-size: spw(12);
                                font-family: var(--ff-sans);
                                color: var(--c-5);
                                position: relative;
                                &::before {
                                    @include content;
                                    width: spw(8);
                                    height: spw(3);
                                    border-right: 1px solid var(--c-8);
                                    border-bottom: 1px solid var(--c-8);
                                    transform: skewX(45deg);
                                    top: spw(14);
                                    left: spw(20);
                                }
                            }
                        }
                    }
                }
                .btn-home {
                    padding: spw(13) spw(20);
                    background: var(--c-f8);
                }
            }
            .btns {
                padding: spw(16) spw(20);
                background: var(--c-e);
                @include flex;
                .btn {
                    padding: 0;
                    width: spw(160);
                    height: spw(40);
                    text-align: center;
                    line-height: spw(40);
                    color: var(--c-wht);
                    background: var(--c-blk);
                    border-radius: 4px;
                    span {
                        padding-left: spw(26);
                        font-size: spw(14);
                        font-family: var(--ff-sans);
                        position: relative;
                        &::before {
                            @include content;
                            width: 16px;
                            height: 6px;
                            border-right: 2px solid var(--c-wht);
                            border-bottom: 1px solid var(--c-wht);
                            transform: skewX(45deg);
                            top: 6px;
                            left: 0;
                        }
                    }
                }
            }
        }
    }
}
main {
    padding-top: 80px;
    // padding-bottom: 80px;
    .content {
        background: var(--c-wht);
    }
    @include sp {
        padding-top: spw(58);
        padding-bottom: 0;
    }
}
.pagetop {
    padding: 120px 0 80px;
    text-align: center;
    background: var(--c-wht);
    position: relative;
    z-index: 1;
    a {
        padding-right: 48px;
        position: relative;
        cursor: pointer;
        &::before, &::after {
            @include content;
            }
        &::before {
            width: 30px;
            height: 0;
            border: 1px solid var(--c-blk);
            top: 0;
            right: 0;
        }
        &::after {
            width: 20px;
            height: 20px;
            border-top: 1px solid var(--c-blk);
            border-right: 1px solid var(--c-blk);
            transform: rotate(-45deg);
            top: 5px;
            right: 5px;
        }
    }
    @include sp {
        padding: spw(80) 0;
    }
}
footer {
    .inner {
        max-width: 1240px;
    }
    .above {
        background: var(--c-f8);
        .inner {
            padding: 64px 40px 46px;
            @include flex;
            align-items: flex-start;
        }
        h1 {
            width: 380px;
            @include flex($v: flex-start);
            flex-direction: column;
            gap: 76px;
            .shoulder {
                font-family: var(--ff-serif);
                font-size: 33px;
            }
            img {
                width: 140px;
            }
        }
        @include sp {
            background: var(--c-wht);
            .inner {
                padding: spw(80) spw(20);
                gap: spw(48);
            }
            h1 {
                width: 100%;
                align-items: center;
                gap: spw(10);
                order: 2;
                .shoulder {
                    font-size: spw(18);
                }
            }
        }
        nav {
            padding-bottom: 88px;
            ul {
                @include flex($h: flex-start, $v: flex-start);
                gap: 48px;
            }
            li {
                font-size: 12px;
                ul {
                    margin-top: 26px;
                    flex-direction: column;
                    gap: 16px;
                    a {
                        padding-left: 14px;
                        position: relative;
                        &::before {
                            @include content;
                            width: 8px;
                            height: 3px;
                            border-right: 1px solid var(--c-blk);
                            border-bottom: 1px solid var(--c-blk);
                            transform: skewX(45deg);
                            top: 6px;
                            left: 0;
                        }
                    }
                }
            }
            @include sp {
                padding-bottom: spw(40);
                width: 100%;
                order: 1;
                ul {
                    flex-direction: column;
                    gap: spw(24);
                    & > li {
                        width: 100%;
                        @include flex($v: flex-start);
                        .catttl {
                            width: 48%;
                            font-size: spw(14);
                        }
                        ul {
                            margin-top: 0;
                            width: 48%;
                        }
                    }
                }
            }
        }
        .btns {
            @include flex;
            gap: 16px;
            position: absolute;
            right: 40px;
            bottom: 40px;
            a {
                width: 160px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                color: var(--c-wht);
                background: var(--c-blk);
                border-radius: 4px;
                display: inline-block;
                span {
                    padding-left: 30px;
                    position: relative;
                    &::before {
                        @include content;
                        width: 16px;
                        height: 6px;
                        border-right: 2px solid var(--c-wht);
                        border-bottom: 1px solid var(--c-wht);
                        transform: skewX(45deg);
                        top: 6px;
                        left: 0;
                    }
                }
            }
            @include sp {
                width: 100%;
                position: relative;
                right: initial;
                bottom: initial;
                gap: 0;
                order: 3;
                a {
                    width: spw(160);
                    height: spw(40);
                    line-height: spw(40);
                }
            }
        }
    }
    .below {
        background: var(--c-e);
        .inner {
            padding: 40px;
            @include flex;
            justify-content: flex-start;
            gap: 40px;
        }
        .sns {
            @include flex;
            gap: 24px;
            a {
                width: 24px;
                height: 24px;
            }
        }
        .copy {
            margin-left: auto;
        }
        @include sp {
            .inner {
                padding: spw(40);
                flex-direction: column;
                gap: spw(16);
            }
            .sns {
                margin-bottom: spw(24);
            }
        }
    }
}