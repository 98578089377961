.is-under:not(.is-above) {
    .submenu {
        display: none;
    }
}
.is-menupos {
    @include pc {
        &.is-under {
            header {
                color: var(--c-wht);
                background: transparent;
                position: absolute;
                top: calc(100vh - 80px);
                .logo-blk {
                    display: none;
                }
                .logo-wht {
                    display: block;
                }
            }
            main {
                padding-top: 0;
            }
        }
        &.is-standby {
            header {
                position: fixed;
                top: -90px;
            }
        }
        &.is-above {
            header {
                width: 100%;
                color: var(--c-blk);
                background: var(--c-wht);
                border-bottom: 1px solid var(--c-e);
                position: fixed;
                top: 0;
                @include ts;
                .logo-blk {
                    display: block;
                }
                .logo-wht {
                    display: none;
                }
            }
        }
    }
}
@include sp {
    .is-menu {
        opacity: 0;
        pointer-events: none;
        @include ts;
        &.is-show {
            opacity: 1;
            pointer-events: initial;
        }
    }
}
.is-inview {
    .is-show-up {
        height: 1.5em;
        line-height: 1;
        overflow: hidden;
        display: block;
        .is-one {
            display: inline-block;
            @include ts($duration: 0.8s);
            transition-timing-function: cubic-bezier(.08,.92,.35,1);
            transform: translateY(3em);
            @for $i from 0 through 100 {
                &:nth-child(#{$i}) {
                    transition-delay: 0.06s*$i;
                }
            }
        }
    }
    &.is-show {
        .is-show-up {
            .is-one {
                transform: translateY(0);
            }
        }
    }
    .is-show-right {
        @include ts($duration: 1s);
        clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0 100%);
        @for $i from 0 through 100 {
            &:nth-child(#{$i}) {
                transition-delay: 0.2s*$i;
            }
        }
    }
    &.is-show {
        .is-show-right {
            clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0 100%);
        }
    }
}
.is-zoom-slider {
    img {
        @include ts($duration: 5s);
        opacity: 0 !important;
    }
    .is-hide {
        transform: scale(1.2) !important;
        opacity: 0 !important;
    }
    .is-show {
        transform: scale(1.2) !important;
        opacity: 1 !important;
    }
}
/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    @include sp {
        margin: 0 spw(10);
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}
.slick-dots {
    @include flex($h: center);
    gap: spw(16);
    button {
        width: spw(6);
        height: spw(6);
        background: var(--c-c);
        border-radius: 50%;
        overflow: hidden;
        font-size: 0;
    }
    .slick-active {
        button {
            background: var(--c-blk);
        }
    }
}
@include sp {
    #colorbox video {
        width: 90vw;    
    }
}