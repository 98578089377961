@use "sass:math";
@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400..800&display=swap'); //400 800
@import url('https://fonts.googleapis.com/css2?family=Shippori+Mincho:wght@400;500;600;700;800&display=swap'); // 400 500 600 700 800
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap'); //100 - 900

// width
$pc-w: 1400;
$sp-w: 375;
$bp: 768px;
@function spw($w) {
  @return ((math.div($w, $sp-w))*100)+vw;
}
@function pcw($w) {
  @return ((math.div($w, $pc-w))*100)+vw;
}

@import "./base/mixin";
@import "./base/reset";
@import "./base/setting";
@import "./layout/layout";
@import "./module/module";
@import "./state/state";
@import "./theme/theme";