@keyframes hvline {
  0% {
    transform-origin: right center;
    transform: scaleX(1);
  }
  33% {
    transform-origin: right center;
    transform: scaleX(0);
  }
  66% {
    transform-origin: left center;
    transform: scaleX(0);
  }
  100% {
    transform-origin: left center;
    transform: scaleX(1);
  }
}