// 横1277pxより小さくすると横スクロール
:root {
	--c-blk: #111;
	--c-3: #333;
	--c-5: #555;
	--c-8: #888;
	--c-c5: #c5c5c5;
	--c-e2: #e2e2e2;
	--c-c: #ccc;
	--c-f8: #f8f8f8;
	--c-fb: #fbfbfb;
	--c-e: #eee;
	--c-org: #fa863d;
	--c-wht: #fff;
	--ff-eb: "EB Garamond", serif; // 400 800
	--ff-serif: "Shippori Mincho", serif; // 400 500 600 700 800
	--ff-sans: "Noto Sans JP", sans-serif; // 100-900
}
// 100 Thin
// 200 Extra-light
// 300 Light
// 400/M/R Regular
// 500 Medium
// 600 Semi-bold
// 700/SB Bold
// 800 Extra-bold
// 900 Black

// serif
// M・RはRegularに
// SBはBoldに変更予定です

// sans
// RはRegularに
// MはMediumに変更予定です

html, body {
	width: 100%;
	height: 100%;
	scroll-behavior: smooth;
	scroll-padding-top: 0;
}
body {
	line-height: 1.7;
	font-size: 14px;
	font-family: var(--ff-sans);
	font-weight: 400;
	color: var(--c-blk);
	@include sp {
		font-size: spw(14);
	}
}
img {
	max-width: 100%;
	height: auto;
	object-fit: contain;
	vertical-align: middle;
}
::-webkit-scrollbar {
	width: 4px; /* 縦スクロールバーの幅 */
}
::-webkit-scrollbar-track { /* スクロールバーの背景 */
	background: var(--c-wht);
}
::-webkit-scrollbar-thumb { /* スクロールバーのサム（つまみ部分） */
	background: #888;
	border-radius: 6px; /* サムの角を丸くする */
}
::-webkit-scrollbar-button { /* スクロールバーのボタン（上下左右のボタン） */
	height: 0;
	background: var(--c-c);
}